import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import GoogleAddressAutocomplete from "../components/inputs/GoogleAddressAutocomplete";

const CreateProspect = ({ user }) => {
  const navigate = useNavigate();
  const [place, setPlace] = useState({});

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      if (place) {
        data.addressLine1 = place.address.addressLine1;
        data.city = place.address.city;
        data.state = place.address.state;
        data.zip = place.address.zip;
        data.lat = place.address.lat;
        data.lng = place.address.lng;
        data.isActive = true;
        data.status = "PROSPECT";
        data.isClosed = false;
        data.rep = user.localUsername;
        data.createdBy = user.localUsername;
        data.coordinates = [place.address.lng, place.address.lat];
        data.locationDb = {
          type: "Point",
          coordinates: [place.address.lng, place.address.lat],
        };

        //axios GET accounts by name
        const responseNameValidation = await axios.get(
          `/api/accounts/validateName/${data.name.toLowerCase().trim()}`
        );

        if (responseNameValidation.data.length > 0) {
          console.log(
            "Account name already exists",
            responseNameValidation.data
          );
          return alert("Account name already exists");
        }

        // //axios POST accounts by address
        // const address = {
        //   addressLine1: data.addressLine1,
        //   addressLine2: data.addressLine2,
        //   city: data.city,
        //   state: data.state,
        //   zip: data.zip,
        // };

        // const responseAddressValidation = await axios.post(
        //   `/api/accounts/validateAddress`,
        //   address
        // );
        // console.log(responseAddressValidation.data);

        //Create account
        //Add axios call to update account
        const res = await axios.post(`/api/accounts`, data);
        console.log(res);
        if (res.status === 201) {
          //Goto account page
          navigate(`/account/${res.data._id}`);
        }
      }

      console.log("No place selected");
    } catch (err) {
      console.log(err);
      return alert("Error updating account", err.message);
    }
  };

  return (
    <Container className="mt-4">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={4} sm={12} className="mt-2">
                <Form.Group>
                  <Form.Label>Type:</Form.Label>
                  <Form.Select
                    aria-label="select account status"
                    {...register("type", { required: true })}
                    size="lg"
                  >
                    <option value="MEDICAL">MEDICAL</option>
                    <option value="LEGAL">LEGAL</option>
                    <option value="OTHER">OTHER</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col className="mt-2">
                <Form.Group>
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter account name"
                    {...register("name", { required: true })}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col md={2} sm={12} className="mt-2">
                <Form.Group>
                  <Form.Label>Frequency:</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter frequency"
                    defaultValue={14}
                    {...register("frequency", { required: true })}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={8} sm={12}>
                <GoogleAddressAutocomplete handleSetPlace={setPlace} />
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Address Line 2:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Suite, Apt, etc."
                    {...register("addressLine2")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} className="mt-2">
                <Form.Group>
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="Phone"
                    {...register("phone")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2">
                <Form.Group>
                  <Form.Label>Email:</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email Address"
                    {...register("email")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4} sm={12} className="mt-2">
                <Form.Group>
                  <Form.Label>Fax:</Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="Fax"
                    {...register("fax")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col className="mt-2">
                <Form.Group>
                  <Form.Label>Web:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Web Address"
                    {...register("web")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button type="submit" variant="primary" size="lg">
                  Create Prospect
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateProspect;
