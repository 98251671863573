import { useState } from "react";
import { SearchIcon } from "../components/Icons";
import { Loading } from "../components/Loading";
import { useSearchAccounts } from "../hooks/useAccounts";
import { TableAccounts } from "../components/TableAccounts";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { calculateDistance } from "../utils/helpers";

const Search = ({ location }) => {
  const [search, setSearch] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const accountsQuery = useSearchAccounts(searchTerm);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchTerm(search);
  };

  if (accountsQuery.isLoading) return <Loading message="Loading..." />;

  let accounts = accountsQuery.data;

  if (accounts) {
    //Add distance to accounts
    if (location) {
      const { latitude, longitude } = location;
      accounts = accounts.map((account) => {
        const distance = calculateDistance(
          latitude,
          longitude,
          account.lat,
          account.lng
        );
        return { ...account, distance };
      });
    }
  }

  return (
    <Container className="mt-4">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h1>Search Accounts</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Account Name or zipcode "
                    aria-label="Account Name or zipcode "
                    aria-describedby="button-search"
                    size="lg"
                    value={search}
                    onChange={handleChange}
                  />
                  <Button
                    type="submit"
                    variant="outline-secondary"
                    id="button-search"
                  >
                    <SearchIcon /> Search
                  </Button>
                </InputGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <TableAccounts
                accounts={accounts}
                showDistance={true}
                showAllInformation={true}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Search;
