import { useState } from "react";
import { useReferralsByYear } from "../../hooks/useReferrals";
import { Loading } from "../../components/Loading";
import TableReferrals from "../../components/TableReferrals";
import _ from "lodash";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const MedicalReferrals = () => {
  const [filter, setFilter] = useState("month");
  //get today
  const today = new Date();
  //get this month
  const thisMonth = today.getMonth() + 1;
  //get this month name
  const thisMonthName = today.toLocaleString("default", { month: "long" });
  //get this year
  const thisYear = today.getFullYear();

  const referralsQuery = useReferralsByYear(thisYear);

  if (referralsQuery.isLoading)
    return <Loading message="Loading Referrals..." />;

  let referrals = referralsQuery.data;

  if (referrals) {
    referrals = referrals.filter(
      (referral) => referral.account.type === "MEDICAL"
    );
    if (filter === "month") {
      referrals = referrals.filter((referral) => referral.month === thisMonth);
    }

    referrals = _.orderBy(referrals, ["date"], ["desc"]);
  }

  return (
    <Container>
      <Card>
        <Card.Header>
          <Row>
            <Col xs={12} md={6}>
              <h3>
                Medical Referrals by {filter} {thisMonthName} {thisYear}
              </h3>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Button
                variant="info"
                onClick={() => setFilter("month")}
                className="me-2"
              >
                This Month
              </Button>
              <Button variant="info" onClick={() => setFilter("year")}>
                This Year
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <TableReferrals referrals={referrals} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MedicalReferrals;
