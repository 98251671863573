import { Player, Controls } from "@lottiefiles/react-lottie-player";

export const Loading = ({ message }) => {
  return (
    <div className="container-login">
      <Player
        autoplay
        loop
        src="https://assets10.lottiefiles.com/packages/lf20_z9ed2jna.json"
        style={{ height: "248px", width: "248px" }}
      >
        <Controls
          visible={false}
          buttons={["play", "repeat", "frame", "debug"]}
        />
      </Player>
      <p>{message}</p>
    </div>
  );
};
