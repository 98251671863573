import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useQuery } from "react-query";
import { Navigate, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import { Loading } from "./components/Loading";
import { Account } from "./Pages/Account";
import { AccountsByLocation } from "./Pages/AccountsByLocation";
import { CheckIn } from "./Pages/CheckIn";
import { AddContact } from "./Pages/Contacts/AddContact";
import { DeleteContact } from "./Pages/Contacts/DeleteContact";
import { EditContact } from "./Pages/Contacts/EditContact";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import NearMe from "./Pages/NearMe";
import "./Style.css";

import ActiveAccounts from "./Pages/ActiveAccounts";
import MyRoute from "./Pages/MyRoute";
import AddNote from "./Pages/Notes/AddNote";
import AddNoteAccount from "./Pages/Notes/AddNoteAccount";
import DeleteNote from "./Pages/Notes/DeleteNote";
import EditNote from "./Pages/Notes/EditNote";
import { IndexNotes } from "./Pages/Notes/Index";
import NoteByDate from "./Pages/Notes/NoteByDate";
import Search from "./Pages/Search";

// Reports
import LegalReferrals from "./Pages/Reports/LegalReferrals";
import MedicalReferrals from "./Pages/Reports/MedicalReferrals";
import OthersReferrals from "./Pages/Reports/OthersReferrals";

//Visits
import DeleteVisit from "./Pages/Visits/Delete";
import EditVisit from "./Pages/Visits/Edit";
import TodayVisits from "./Pages/Visits/Today";

//Routes
import HomeAccounts from "./Pages/Admin/Accounts/HomeAccounts";
import CreateRoute from "./Pages/CreateRoute";
import Initials from "./Pages/Initials";
import EditAccount from "./Pages/Admin/Accounts/EditAccount";
import HomeAdmin from "./Pages/Admin/HomeAdmin";
import ReportVisit from "./Pages/Visits/Report";

//Admin Accounts

//Admin Reports
import HomeReports from "./Pages/Admin/Reports/HomeReports";
import CheckInReport from "./Pages/Admin/Reports/CheckInReport";
import RouteReport from "./Pages/Admin/Reports/RouteReport";
import ZoneReport from "./Pages/Admin/Reports/ZoneReport";
import VisitByTouchReport from "./Pages/Admin/Reports/VisitByTouchReport";
import QcCallsReport from "./Pages/Admin/Reports/QcCallsReport";
import CreateCall from "./Pages/Calls/CreateCall";
import TodayCalls from "./Pages/Calls/TodayCalls";
import IndexQcCalls from "./Pages/Calls/IndexQcCalls";
import DeleteCall from "./Pages/Calls/DeleteCall";
import EditCall from "./Pages/Calls/EditCall";
import CreateLocation from "./Pages/Locations/CreateLocation";
import CreateProspect from "./Pages/CreateProspect";
import { CheckInFromRoute } from "./Pages/CheckInFromRoute";
import InactiveAccounts from "./Pages/InactiveAccounts";
import ProspectAccounts from "./Pages/PropectAccounts";
import EditAccountAddress from "./Pages/Admin/Accounts/EditAddress";
import PromoCreate from "./Pages/Promos/PromoCreate";
import PromoReport from "./Pages/Admin/Reports/PromoReport";
import CheckRouteByDate from "./Pages/CheckRouteByDate";
import InitialDeliveriesReport from "./Pages/Admin/Reports/InitialDeliveriesReport";
import PotentialsQcCalls from "./Pages/Reports/PotentialsQcCalls";

async function getLocationPromise() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

function App() {
  function requestMe() {
    return axios.get("/api/auth/me");
  }

  const userQuery = useQuery("me", requestMe);

  const locationQuery = useQuery(["Location"], getLocationPromise, {
    cacheTime: 0,
  });

  let location = null;

  if (locationQuery.isSuccess) {
    location = locationQuery.data.coords;
  }

  if (userQuery.isLoading) {
    return <Loading message="Loading..." />;
  }

  if (locationQuery.isLoading) {
    return <Loading message="Getting Your Location..." />;
  }

  if (userQuery.isError) {
    return <Navigate to="/login" />;
  }

  const { user } = userQuery.data.data;

  return (
    <>
      <main>
        {user ? <Header user={user} /> : null}
        <Routes>
          <Route
            path="/"
            element={
              user ? (
                <Dashboard user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/createProspect"
            element={
              user ? (
                <CreateProspect user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route path="/login" element={<Login user={user} />} />
          <Route
            path="/nearme"
            element={
              user ? <NearMe user={user} /> : <Navigate to={"/login"} replace />
            }
          />
          <Route
            path="/location"
            element={
              user ? (
                <AccountsByLocation user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/initials"
            element={
              user ? (
                <Initials user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/active"
            element={
              user ? (
                <ActiveAccounts user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/inactive"
            element={
              user ? (
                <InactiveAccounts user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/prospects"
            element={
              user ? (
                <ProspectAccounts user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path="/search"
            element={
              user ? (
                <Search user={user} location={location} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path="/account/:id"
            element={
              user ? (
                <Account user={user} location={location} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/contacts/addcontact/:id"
            element={user ? <AddContact /> : <Navigate to={"/login"} replace />}
          />
          <Route
            path="/contacts/deleteContact/:id"
            element={
              user ? (
                <DeleteContact user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/contacts/editContact/:id"
            element={
              user ? <EditContact /> : <Navigate to={"/login"} replace />
            }
          />
          <Route
            path="/notes"
            element={
              user ? (
                <IndexNotes user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/notes/create"
            element={
              user ? (
                <AddNote location={location} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/notes/createaccount/:id"
            element={
              user ? (
                <AddNoteAccount location={location} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/notes/edit/:id"
            element={
              user ? (
                <EditNote location={location} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/notes/delete/:id"
            element={user ? <DeleteNote /> : <Navigate to={"/login"} replace />}
          />
          <Route
            path="/notes/bydate"
            element={
              user ? (
                <NoteByDate user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/checkIn/:id"
            element={
              user ? (
                <CheckIn location={location} user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/checkInFromRoute/:id/:accountId"
            element={
              user ? (
                <CheckInFromRoute location={location} user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path="/myroute/"
            element={
              user ? (
                <MyRoute user={user} location={location} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/checkRoute"
            element={
              user ? (
                <CheckRouteByDate user={user} location={location} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          {/* Qc Calls */}
          <Route
            path="/qcCalls"
            element={
              user ? (
                <IndexQcCalls user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/qcCalls/create/:accountId"
            element={
              user ? (
                <CreateCall user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/qcCalls/delete/:id"
            element={
              user ? (
                <DeleteCall user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/qcCalls/edit/:id"
            element={
              user ? (
                <EditCall user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/qcCalls/today"
            element={
              user ? (
                <TodayCalls user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          {/* End Locations  */}
          <Route
            path="/Locations/create"
            element={
              user ? (
                <CreateLocation user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          {/* Reports */}
          <Route
            path="/admin/reports/initials"
            element={
              user ? (
                <InitialDeliveriesReport />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path="/admin/reports/potentialsQcCalls"
            element={
              user ? <PotentialsQcCalls /> : <Navigate to={"/login"} replace />
            }
          />

          <Route
            path="/reports/legal-referrals"
            element={
              user ? <LegalReferrals /> : <Navigate to={"/login"} replace />
            }
          />
          <Route
            path="/reports/medical-referrals"
            element={
              user ? <MedicalReferrals /> : <Navigate to={"/login"} replace />
            }
          />
          <Route
            path="/reports/others-referrals"
            element={
              user ? <OthersReferrals /> : <Navigate to={"/login"} replace />
            }
          />
          <Route
            path="/Admin/reports/qcCalls"
            element={
              user ? <QcCallsReport /> : <Navigate to={"/login"} replace />
            }
          />
          <Route
            path="/Admin/reports/promos"
            element={
              user ? <PromoReport /> : <Navigate to={"/login"} replace />
            }
          />
          {/* Visits */}
          <Route
            path="/visits/edit/:id/:accountId"
            element={
              user ? (
                <EditVisit user={user} location={location} />
              ) : (
                <Navigate to={"/"} />
              )
            }
          />
          <Route
            path="/visits/delete/:id"
            element={
              user ? (
                <DeleteVisit user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/visits/today"
            element={
              user ? (
                <TodayVisits user={user} />
              ) : (
                <Navigate to={"/login"} repalce />
              )
            }
          />
          <Route
            path="/visits/report"
            element={
              user ? (
                <ReportVisit user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          {/* Routes */}
          <Route
            path="/CreateRoute"
            element={
              user ? (
                <CreateRoute user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          {/* Routes Admin Accounts  */}
          <Route
            path="/Admin/Accounts"
            element={
              user ? (
                <HomeAccounts user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/admin/account/edit/:id"
            element={
              user ? (
                <EditAccount user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/admin/account/editAccountAddress/:id"
            element={
              user ? (
                <EditAccountAddress user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          {/* Routes Admin reports */}
          <Route
            path="/admin"
            element={
              user && user.isAdmin ? (
                <HomeAdmin user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/admin/reports"
            element={
              user ? (
                <HomeReports user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          <Route
            path="/admin/reports/checkins"
            element={
              user ? (
                <CheckInReport user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/admin/reports/visitsByTouch"
            element={
              user ? (
                <VisitByTouchReport user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/admin/reports/routes"
            element={
              user ? (
                <RouteReport user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
          <Route
            path="/admin/reports/zone"
            element={
              user ? (
                <ZoneReport user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />

          {/* Promo Routes */}
          <Route
            path="/promos/create"
            element={
              user ? (
                <PromoCreate user={user} />
              ) : (
                <Navigate to={"/login"} replace />
              )
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default App;
