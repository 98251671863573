//Calculate distance between two locations
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  const miles = kmToMiles(d);
  //   if (miles < 1) {
  //     return milesToFeet(miles).toFixed(2);
  //   }
  return miles.toFixed(2);
};

//Convert degrees to radians
const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

//Convert km to miles
export const kmToMiles = (km) => {
  return km * 0.621371;
};

//Convert miles to feet
export const milesToFeet = (miles) => {
  return miles * 5280;
};

// Get tiem from date
export const timeAMPMFromDate = (dbDate) => {
  const date = new Date(dbDate);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  const hours12 = hours % 12;
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours12}:${minutesStr} ${ampm}`;
};

//Get short date from date
export const shortDate = (d) => {
  let date = new Date(`${d}T00:00`);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
};

//Get short date and time from date
export const shortDateAndTime = (d) => {
  let date = new Date(d);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
};

//Get time from date with AM/PM

export const timeFromDate = (d) => {
  let date = new Date(d);
  return date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

//Get short date and time from date
export const shortDateNotTime = (d) => {
  let date = new Date(d);
  return date.toLocaleDateString("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
};

//Get relative date from date
export const relativeDate = (d) => {
  let date = new Date(`${d}T00:00`);
  const now = new Date();
  const days = Math.floor((now - date) / (1000 * 60 * 60 * 24));
  if (days < 1) {
    const time = timeFromDate(d);
    return `Today at ${time}`;
  } else if (days === 1) {
    return `Yesterday at ${timeFromDate(d)}`;
  }
  return days + " days ago";
};

//Get relative date Just Today and Yesterday from date
export const relativeTodayDate = (d) => {
  let date = new Date(d);
  const now = new Date();
  const days = Math.floor((now - date) / (1000 * 60 * 60 * 24));
  if (days < 1) {
    const time = timeFromDate(d);
    return `Today at ${time}`;
  } else if (days === 1) {
    return `Yesterday at ${timeFromDate(d)}`;
  }
  return shortDateAndTime(d);
};

//Get relative date Just Today and Yesterday from date
export const relativeDateAndShortDate = (d) => {
  let date = new Date(d);
  const now = new Date();
  const days = Math.floor((now - date) / (1000 * 60 * 60 * 24));
  if (days < 1) {
    return "Today";
  } else if (days === 1) {
    return "Yesterday";
  }
  return shortDateNotTime(d);
};

//GET Mondaay of the week
export const getMonday = (d) => {
  d = new Date(d);
  var day = d.getDay(),
    diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

//GET Date input value format
export const getDateInputFormat = (d) => {
  d = new Date(d);
  var day = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

//GET BADGE TAG
export const getBadgeTag = (tag) => {
  switch (tag.toUpperCase()) {
    case "MEDICAL":
      return <span className="badge bg-info">{tag}</span>;
    case "LEGAL":
      return <span className="badge bg-warning">{tag}</span>;
    case "OTHER":
      return <span className="badge bg-dark">{tag}</span>;
    default:
      return <span className="badge bg-secondary">{tag}</span>;
  }
};

//get badge Status
export const getBadgeStatus = (status) => {
  switch (status) {
    case "ACTIVE":
      return <span className="badge bg-success">{status}</span>;
    case "PROSPECT":
      return <span className="badge bg-secondary">{status}</span>;
    case "INACTIVE":
      return <span className="badge bg-warning">{status}</span>;
    default:
      return <span className="badge bg-secondary">{status}</span>;
  }
};
