import { useInitialsNotDelivered } from "../hooks//useInitials";
import { Loading } from "../components/Loading";
import _ from "lodash";
import TableInitials from "../components/TableInitials";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Initials = ({ user }) => {
  const initialsQuery = useInitialsNotDelivered();

  if (initialsQuery.isLoading) {
    return <Loading message="Loading Initials..." />;
  }

  const initials = initialsQuery.data;

  function handleRefreshData() {
    initialsQuery.refetch();
  }

  //Order By  Initials by rep by firm
  const initialsByRep = _.orderBy(initials, ["attorney"], ["asc"]);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Initials</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableInitials
            initials={initialsByRep}
            handleRefreshData={handleRefreshData}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Initials;
