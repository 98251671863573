//Get Date in input format
export const inputDateFormat = (d) => {
  d = new Date(d);
  var day = d.getDate();
  var month = d.getMonth() + 1;
  var year = d.getFullYear();
  return `${year}-${month < 10 ? `0${month}` : month}-${
    day < 10 ? `0${day}` : day
  }`;
};

//retunr today input format
export const todayInputFormat = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};

//return now time from new Date()
export const nowTime = () => {
  const today = new Date();
  const hh = String(today.getHours()).padStart(2, "0");
  const mm = String(today.getMinutes()).padStart(2, "0");
  const ss = String(today.getSeconds()).padStart(2, "0");

  return hh + ":" + mm + ":" + ss;
};
