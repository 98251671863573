import Table from "react-bootstrap/Table";
import { getTouchIcon } from "../utils/constantValues";
import _ from "lodash";

const TableCheckIns = (props) => {
  let { checkIns } = props;

  //Order CheckIns by date
  checkIns = _.orderBy(checkIns, ["checkInDate"], ["asc"]);

  return (
    <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          <th>#</th>
          <th></th>
          <th>Date</th>
          <th>Account</th>
          <th>Contact</th>
          <th>Touch</th>
        </tr>
      </thead>
      <tbody>
        {checkIns.map((checkIn, Index) => {
          return (
            <tr key={checkIn._id}>
              <td className="text-center">{Index + 1}</td>
              <td className="text-center">{getTouchIcon(checkIn.touch)}</td>
              <td>{checkIn.localDate}</td>
              <td style={{ maxWidth: "350px" }}>
                {checkIn.account ? checkIn.account.name : null}
              </td>
              <td>{checkIn.contact}</td>
              <td className="text-center">{checkIn.mainTouch}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default TableCheckIns;
