import { useQuery } from "react-query";
import axios from "axios";

//GET rep Actives
export function useRepActives() {
  const query = useQuery(
    ["rep", "actives"],
    async () => {
      const res = await axios.get(`/api/users/rep/actives`);
      return res.data;
    },
    {
      enabled: true,
    }
  );
  return query;
}
