import { useQuery } from "react-query";
import axios from "axios";

//GET Notes by date and rep
export function useNotesByDate(date, rep) {
  const notesQuery = useQuery(
    ["notes", date, rep],
    async () => {
      const res = await axios.get(`/api/notes/date/${date}/${rep}/`);
      return res.data;
    },
    {
      enabled: !!date && !!rep,
    }
  );
  return notesQuery;
}

//GET note by id
export function useNoteById(id) {
  const noteQuery = useQuery(
    ["note", id],
    async () => {
      const res = await axios.get(`/api/notes/${id}`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
  return noteQuery;
}

// Get all notes by account
export function useNotesByAccount(accountId) {
  const notesQuery = useQuery(
    ["notes", "account", accountId],
    async () => {
      const res = await axios.get(`/api/notes/account/${accountId}`);
      return res.data;
    },
    {
      enabled: !!accountId,
    }
  );
  return notesQuery;
}

// Get all notes by account with limit
export function useNotesByAccountWithLimit(accountId, limit) {
  const notesQuery = useQuery(
    ["notes", "account-limit", accountId, limit],
    async () => {
      const res = await axios.get(
        `/api/notes/account-limit/${accountId}/${limit}`
      );
      return res.data;
    },
    {
      enabled: !!accountId && !!limit,
    }
  );
  return notesQuery;
}
