import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useAccount } from "../../hooks/useAccounts";
import { Link } from "react-router-dom";
import { PhoneInput } from "../../components/inputs/PhoneInput";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export const AddContact = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValues, setFormValues] = React.useState({
    name: "",
    birthday: "",
    email: "",
    phone: "",
    position: "",
    notes: "",
  });

  async function handleSubmit(event) {
    event.preventDefault();
    //Validate  values formValues
    if (!formValues.name) return alert("Name is required");
    if (!formValues.position) return alert("Position is required");

    //Call api using axios
    const response = await axios.post("/api/account-contacts", {
      ...formValues,
      AccountId: id,
    });
    if (response.status === 201) {
      //Redirect to account page
      navigate(`/account/${id}`);
    }
  }

  function handleInputChange(event) {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  }

  const accountQuery = useAccount(id);

  if (accountQuery.isLoading) {
    return <Loading message="Loading Account Information..." />;
  }

  const account = accountQuery.data;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h1>Add Contact {account.name}</h1>
            <Link to={`/account/${account._id}`} className="button button-info">
              Back
            </Link>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contact Name"
                    name="name"
                    value={formValues.name}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Position</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Position"
                    name="position"
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Birthday</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Birthday"
                    name="birthday"
                    value={formValues.birthday}
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="phone"
                    placeholder="Phone"
                    name="phone"
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Notes</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Notes"
                    name="notes"
                    onChange={handleInputChange}
                  />
                </Form.Group>
                <div className="d-flex justify-content-center mt-4">
                  <Button variant="primary" type="submit" size="lg">
                    Create Contact
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <div className="container-row flex-end mt-10"></div>
      <div className="container-row">
        <div className="white-card text-card flex-column mt-10">
          <div className="container-row mt-20"></div>
        </div>
      </div>
    </Container>
  );
};
