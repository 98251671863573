import { COLORS } from "../utils/colors";
import styled from "styled-components";
import { Link } from "react-router-dom";

const CardTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  font-family: "Noto Sans", sans-serif;
  color: ${COLORS.white};
  background-color: ${COLORS.darkGray};
  width: 100%;
  text-align: center;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

const CardImage = styled.img`
  width: 80px;
  height: 80px;
  margin-top: 10px;
`;

export const NavbarLink = styled(Link)`
  text-decoration: none;
`;

export const HomeLink = ({ icon, title, to, initials = 0 }) => {
  return (
    <NavbarLink to={to}>
      <div className="menu-card mt-2">
        <CardImage src={icon} />
        <CardTitle>{initials > 0 ? `${initials} ${title}` : title}</CardTitle>
      </div>
    </NavbarLink>
  );
};
