import { useQuery } from "react-query";
import axios from "axios";

//GET Initials
export function useInitials() {
  const query = useQuery(["initials"], async () => {
    const res = await axios.get(`/api/initials`);
    return res.data;
  });
  return query;
}

//GET Initials NOT delivered
export function useInitialsNotDelivered() {
  const initialsQuery = useQuery(["initials", "NotDelivered"], async () => {
    const res = await axios.get("/api/initials/not-delivered");
    return res.data;
  });
  return initialsQuery;
}

//GET Initials delivered from and to
export function useInitialsDeliveredFromTo(from, to) {
  const initialsQuery = useQuery(
    ["initials", "Delivered", from, to],
    async () => {
      const res = await axios.get(
        `/api/initials/delivered/from/${from}/to/${to}`
      );
      return res.data;
    },
    {
      enabled: !!from && !!to,
    }
  );
  return initialsQuery;
}
