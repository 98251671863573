import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export const AccountInformation = ({ account }) => {
  return (
    <Row>
      <Col>
        <Row>
          <Col xs={12} md={3}>
            Address:
          </Col>
          <Col>{account.address}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Phone:
          </Col>
          <Col>{account.phone}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Fax:
          </Col>
          <Col>{account.fax}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Email:
          </Col>
          <Col>{account.email}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Website:
          </Col>
          <Col>{account.web}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Type:
          </Col>
          <Col>{account.type}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Rep:
          </Col>
          <Col>{account.rep}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Frequency:
          </Col>
          <Col>{account.frequency}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Last Visit:
          </Col>
          <Col>
            {account.lastVisitDaysAgo} by {account.lastVisitBy}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Is Active:
          </Col>
          <Col>{account.isActive}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Closed:
          </Col>
          <Col>{account.isClosed ? "YES" : "NO"}</Col>
        </Row>
        <Row>
          <Col xs={12} md={3}>
            Last Referral:
          </Col>
          <Col>{account.lastReferralShortDate}</Col>
        </Row>
      </Col>
    </Row>
  );
};
