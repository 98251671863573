import { useState } from "react";
import { useReferralsByYear } from "../../hooks/useReferrals";
import { Loading } from "../../components/Loading";
import TableReferrals from "../../components/TableReferrals";
import _ from "lodash";

const OthersReferrals = () => {
  const [filter, setFilter] = useState("month");
  //get today
  const today = new Date();
  //get this month
  const thisMonth = today.getMonth() + 1;
  //get this month name
  const thisMonthName = today.toLocaleString("default", { month: "long" });
  //get this year
  const thisYear = today.getFullYear();

  const referralsQuery = useReferralsByYear(thisYear);

  if (referralsQuery.isLoading)
    return <Loading message="Loading Referrals..." />;

  let referrals = referralsQuery.data;

  if (referrals) {
    referrals = referrals.filter(
      (referral) => referral.account.type === "OTHERS"
    );
    if (filter === "month") {
      referrals = referrals.filter((referral) => referral.month === thisMonth);
    }

    referrals = _.orderBy(referrals, ["date"], ["desc"]);
  }

  return (
    <div className="container-base mb-40">
      <div className="container-row mt-20">
        <h2>
          Others Referrals by {filter} {thisMonthName} {thisYear}
        </h2>
      </div>
      <div className="container-row mt-20">
        <button
          className="button button-info mr-10"
          onClick={() => setFilter("month")}
        >
          This Month
        </button>
        <button
          className="button button-info mr-10"
          onClick={() => setFilter("year")}
        >
          This Year
        </button>
      </div>
      <div className="container-row mt-20">
        <TableReferrals referrals={referrals} />
      </div>
    </div>
  );
};

export default OthersReferrals;
