import { useState } from "react";
import { useLocationsByUser } from "../hooks/useLocation";
import { useDirections } from "../hooks/useDirections";
import { getMonday, getDateInputFormat } from "../utils/helpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Loading } from "../components/Loading";
import Container from "react-bootstrap/Container";
import { TableRoute } from "../components/TableRoute";
import Form from "react-bootstrap/Form";

const CheckRouteByDate = ({ user, location }) => {
  const today = new Date();
  const [date, setDate] = useState(getDateInputFormat(getMonday(today)));
  const [destination, setDestination] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const [zoneTitle, setZoneTitle] = useState({
    day: "",
    destination: "",
  });
  const generateRoute = "y";
  const rep = user.localUsername;
  const locationsQuery = useLocationsByUser(rep);

  const onHandleChange = (e) => {
    //Set the date
    setDate(e.target.value);
    setZoneTitle({ ...zoneTitle, day: e.target.value });
  };

  const handleClick = (e) => {
    e.preventDefault();
  };

  //Sort Accounts by Google Route
  const directionsQuery = useDirections(
    rep,
    date,
    generateRoute,
    location.latitude,
    location.longitude,
    destination.lat,
    destination.lng
  );
  if (directionsQuery.isLoading || useLocationsByUser.isLoading)
    return <Loading message={`Loading route`} />;

  const directions = directionsQuery.data;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <Row>
            <Col xs={12} md={6}>
              <h1>Route Date </h1>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      type="date"
                      size="lg"
                      name="date"
                      value={date}
                      onChange={onHandleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6}>
              <div>
                <h1>Destination</h1>
                <div className="mt-2">
                  {locationsQuery.isSuccess &&
                    locationsQuery.data.map((location) => (
                      <Button
                        variant="primary"
                        className="me-2"
                        size="lg"
                        key={location._id}
                        onClick={() => {
                          const data = {
                            name: location.name,
                            lat: location.lat,
                            lng: location.lng,
                          };
                          setDestination(data);
                          setZoneTitle({
                            ...zoneTitle,
                            destination: location.name,
                          });
                        }}
                      >
                        {location.name}
                      </Button>
                    ))}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {!directions && (
                <div className="d-flex justify-content-center">
                  <h4 className="text-danger">Please create your route</h4>
                </div>
              )}
              {directionsQuery.isSuccess && (
                <TableRoute
                  routes={directionsQuery.data.route}
                  title={zoneTitle}
                  location={location}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CheckRouteByDate;
