import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { useNavigate } from "react-router-dom";

const HomeAdmin = () => {
  const navigate = useNavigate();

  const accountsClicked = () => {
    navigate("/admin/accounts");
  };

  const reportsClicked = () => {
    navigate("/admin/reports");
  };

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <h2>Admin</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <ListGroup>
                <ListGroup.Item action onClick={accountsClicked}>
                  Accounts
                </ListGroup.Item>
                <ListGroup.Item action onClick={reportsClicked}>
                  Reports
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col></Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HomeAdmin;
