import React from "react";
import { Link } from "react-router-dom";
import { DeleteIcon, EditIcon } from "./Icons";
import Table from "react-bootstrap/Table";

export const TableAccountContacts = ({ contacts, isAdmin = false }) => {
  return (
    contacts && (
      <Table responsive="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Position</th>
            <th>Email</th>
            <th>Phone</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact._id}>
              <td>{contact.name}</td>
              <td>{contact.position}</td>
              <td>{contact.email}</td>
              <td>{contact.phone}</td>
              <td style={{ textAlign: "right" }}>
                <Link
                  to={`/contacts/editContact/${contact._id}`}
                  className="button button-warning mr-5"
                >
                  <EditIcon />
                </Link>
                {isAdmin && (
                  <Link
                    to={`/contacts/deleteContact/${contact._id}`}
                    className="button button-danger"
                  >
                    <DeleteIcon />
                  </Link>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};
