// RouteCard.js

import React from "react";
import { Card, Row, Col, ListGroup, Button } from "react-bootstrap";
import { getBadgeTag, getBadgeStatus } from "../utils/helpers";

function RouteCard({ routes, date, onRemove }) {
  return (
    <Card className="mt-2">
      <Card.Header className="d-flex flex-column align-items-center">
        {routes && (
          <>
            <h4>Route {date}</h4>
            <h5>({routes.length} Of 25)</h5>
          </>
        )}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <ListGroup as="ol">
              {routes &&
                routes.map((route, index) => (
                  <ListGroup.Item
                    key={route._id}
                    as="li"
                    className={`d-flex flex-column ${
                      route.account.needVisit
                        ? "list-group-item-danger"
                        : "list-group-item-success"
                    }`}
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">
                        {index + 1} - {route.account.name}
                      </h5>
                      <small className="text-muted">
                        {route.account.lastVisitDaysAgo}
                      </small>
                    </div>
                    <p className="mb-1">
                      {getBadgeTag(route.account.type)}{" "}
                      {getBadgeStatus(route.account.statusV2)}
                    </p>
                    <p className="mb-1">{route.account.address}</p>
                    <small className="text-muted">
                      {route.account.zone} ({route.account.zq})
                      {route.account.rep}
                    </small>
                    <div className="d-grid gap-2 mt-2">
                      {route.status !== "Completed" ? (
                        <Button
                          variant="danger"
                          size="lg"
                          type="button"
                          onClick={() => onRemove(route)}
                        >
                          Remove
                        </Button>
                      ) : null}
                    </div>
                  </ListGroup.Item>
                ))}
            </ListGroup>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default RouteCard;
