import { relativeDateAndShortDate } from "../utils/helpers";
import Table from "react-bootstrap/Table";

const TableReferrals = ({ referrals }) => {
  if (!referrals) return null;

  return (
    <Table striped bordered hover responsive="sm" className="mt-2">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Account</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {referrals.map((referral, index) => (
          <tr key={referral._id}>
            <td>{index + 1}</td>
            <td>{relativeDateAndShortDate(referral.date)}</td>
            <td>{referral.account.name}</td>
            <td>{referral.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableReferrals;
