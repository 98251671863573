export const ICONS = {
  nearMeIcon: "/images/menu-icons/nearme.svg",
  byLocation: "/images/menu-icons/location.svg",
  notes: "/images/menu-icons/notes.svg",
  active: "/images/menu-icons/active.svg",
  home: "/images/home.svg",
  route: "/images/menu-icons/route.svg",
  today: "/images/menu-icons/today.svg",
  search: "/images/menu-icons/search.svg",
  initials: "/images/menu-icons/initials.svg",
  createRoute: "/images/menu-icons/create-route.svg",
  admin: "/images/menu-icons/admin.svg",
  qcCalls: "/images/menu-icons/qcCalls.svg",
  prospect: "/images/menu-icons/prospect.svg",
  prospects: "/images/menu-icons/prospects.svg",
  inactive: "/images/menu-icons/inactive.svg",
};

export const STATUS = {
  Active: "Active",
  Inactive: "Inactive",
  Completed: "Completed",
  Pending: "Pending",
  Cancelled: "Cancelled",
  All: "All",
  Today: "Today",
  Tomorrow: "Tomorrow",
  ThisWeek: "This Week",
  ThisMonth: "This Month",
  ThisYear: "This Year",
  Overdue: "Overdue",
  Upcoming: "Upcoming",
  Delivered: "Delivered",
};
export const TOUCHES = [
  "MAKE THE SHINE",
  "PERSONALIZED",
  "COLOR CODE",
  "COLOR CODE PRESENTATION",
  "FEEDBACK",
  "LUNCH",
];

export const LOCATIONS = ["CE", "NE", "NW", "SE", "SW"];

export const MAX_ROUTE_POINTS = 25;

export const CHECK_IN_MIN_DISTANCE = Number(
  process.env.REACT_APP_CHECK_IN_MIN_DISTANCE
);

export const getTouchIcon = (icon) => {
  switch (icon) {
    case "HOT":
      return "🔥";
    case "WARM":
      return "🌤";
    case "COLD":
      return "🥶";
    default:
      return "🤷‍♂️";
  }
};

export const QUADRANT1 = ["Z1Q1", "Z2Q1", "Z3Q1", "Z4Q1"];
export const QUADRANT2 = ["Z1Q2", "Z2Q2", "Z3Q2", "Z4Q2"];

export const LIMIT_DAYS = 30;
