import { useQuery } from "react-query";
import axios from "axios";

//Get All Calls by account Id
export function useCallsByAccount(accountId) {
  const query = useQuery(
    ["call-account", accountId],
    async () => {
      const res = await axios.get(`/api/calls/by-account/${accountId}`);
      return res.data;
    },
    {
      enabled: !!accountId,
    }
  );
  return query;
}

//Get All Calls by date range
export function useCallsByDateRange(from, to) {
  const query = useQuery(
    ["call-date-range", from, to],
    async () => {
      const res = await axios.get(`/api/calls/by-date-range/${from}/${to}`);
      return res.data;
    },
    {
      enabled: !!from && !!to,
    }
  );
  return query;
}

//Get Call by account with limit
export function useCallsByAccountWithLimit(accountId, limit) {
  const query = useQuery(
    ["call-limit", limit, accountId],
    async () => {
      const res = await axios.get(
        `/api/calls/by-account-limit/${accountId}/${limit}`
      );
      return res.data;
    },
    {
      enabled: !!accountId && !!limit,
    }
  );
  return query;
}

//Get Call by id
export function useCallById(id) {
  const query = useQuery(
    ["call-limit", id],
    async () => {
      const res = await axios.get(`/api/calls/${id}`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
  return query;
}
