import { useRef, useEffect } from "react";
import _ from "lodash";

//import "./styles.css";
const GoogleAddressAutocomplete = ({ handleSetPlace }) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    componentRestrictions: { country: "us" },
    fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,
    types: ["address"],
  };
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      place.address = getAddressFromPlace(place);

      handleSetPlace(place);
    });
  }, []);

  const getAddressFromPlace = (place) => {
    //find address line 1 in place.address_components
    const addressStreetNumber = _.find(
      place.address_components,
      (component) => {
        return component.types.includes("street_number");
      }
    );
    //find address line 2 in place.address_components
    const addressStreetName = _.find(place.address_components, (component) => {
      return component.types.includes("route");
    });
    //find city  in place.address_components
    const city = _.find(place.address_components, (component) => {
      return component.types.includes("locality");
    });
    //find state in place.address_components
    const state = _.find(place.address_components, (component) => {
      return component.types.includes("administrative_area_level_1");
    });
    //find zip in place.address_components
    const zip = _.find(place.address_components, (component) => {
      return component.types.includes("postal_code");
    });

    const address = {
      addressLine1: `${addressStreetNumber.long_name} ${addressStreetName.long_name}`,
      city: city.long_name,
      state: state.short_name,
      zip: zip.long_name,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };

    return address;
  };

  return (
    <div>
      <label style={{ marginBottom: "8px" }}>Address:</label>
      <input className="form-control form-control-lg" ref={inputRef} />
    </div>
  );
};
export default GoogleAddressAutocomplete;
