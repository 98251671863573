import { useQuery } from "react-query";
import axios from "axios";

export function usePromoByMonthYearAndRep(month, year, rep) {
  const query = useQuery(
    ["promo-month-year-rep", month, year, rep],
    async () => {
      const res = await axios.get(
        `/api/promos/monthYearRep/${rep}/${month}/${year}`
      );
      return res.data;
    },
    {
      enabled: !!month && !!year && !!rep,
    }
  );
  return query;
}

//Get all promos
export function usePromos() {
  const query = useQuery(["promos"], async () => {
    const res = await axios.get("/api/promos");
    return res.data;
  });
  return query;
}
