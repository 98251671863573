import { useState } from "react";
import { Link } from "react-router-dom";
import { useCallsByDateRange } from "../../../hooks/useCalls";
import TableCalls from "../../../components/TableCalls";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Loading } from "../../../components/Loading";

const QcCallsReport = () => {
  //Today input format
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  const todayFormatted = yyyy + "-" + mm + "-" + dd;

  const [query, setQuery] = useState({
    from: todayFormatted,
    to: todayFormatted,
  });

  const callsQuery = useCallsByDateRange(query.from, query.to);

  if (callsQuery.isLoading) return <Loading message="Loading" />;

  const onHandleChange = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(query);
  };

  return (
    <Container className="my-4">
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <h3>Qc-Calls Report</h3>
                <div>
                  <Link to="/admin/reports">Reports</Link>
                  <span className="mx-1">|</span>

                  <Link to="/admin/reports/potentialsQcCalls">Potencials</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  size="lg"
                  name="from"
                  value={query.from}
                  onChange={onHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  size="lg"
                  name="to"
                  value={query.to}
                  onChange={onHandleChange}
                />
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={2}
              className="d-flex flex-column justify-content-end"
            >
              <Button
                type="button"
                onClick={handleClick}
                variant="primary"
                size="lg"
              >
                View
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex justify-content-center">
              <h3>
                Qc-Calls From {query.from} To {query.to}
              </h3>
            </Col>
            {callsQuery.isSuccess && <TableCalls calls={callsQuery.data} />}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default QcCallsReport;
