import { useQuery } from "react-query";
import axios from "axios";

//Get all location by username
export function useLocationsByUser(username) {
  const query = useQuery(
    ["locations", username],
    async () => {
      const res = await axios.get(`/api/locations/user/${username}`);
      return res.data;
    },
    {
      enabled: !!username,
    }
  );
  return query;
}
