import React from "react";
import { relativeTodayDate } from "../utils/helpers";
import { getTouchIcon } from "../utils/constantValues";
import { Link } from "react-router-dom";
import { DeleteIcon, EditIcon } from "./Icons";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

export const TableVisits = ({ visits, edit = false }) => {
  if (!visits) return null;

  return (
    <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Account</th>
          <th>Date</th>
          <th>Rep</th>
          <th style={{ textAlign: "center" }} colSpan="2">
            Touch
          </th>
          <th>Contact</th>
          <th>Promo</th>
          <th>Note</th>
          {edit ? <th></th> : null}
        </tr>
      </thead>
      <tbody>
        {visits.map((visit, index) => (
          <tr key={visit._id}>
            <td>{index + 1}</td>
            <td>{visit.account ? visit.account.name : null}</td>
            <td>{relativeTodayDate(visit.date)}</td>
            <td>{visit.rep}</td>
            <td style={{ textAlign: "center" }}>{getTouchIcon(visit.touch)}</td>
            <td>{visit.mainTouch}</td>
            <td>{visit.contact}</td>
            <td>{visit.promo}</td>
            <td>{visit.notes}</td>
            {edit ? (
              <td style={{ textAlign: "right", width: "120px" }}>
                <Link to={`/visits/delete/${visit._id}/`}>
                  <Button variant="danger" className="me-2">
                    <DeleteIcon />
                  </Button>
                </Link>
                <Link to={`/visits/edit/${visit._id}/${visit.account._id}`}>
                  <Button variant="warning">
                    <EditIcon />
                  </Button>
                </Link>
              </td>
            ) : null}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
