import React from "react";
import { relativeTodayDate } from "../utils/helpers";
import { getTouchIcon } from "../utils/constantValues";

import Table from "react-bootstrap/Table";

export const TableInitialsDelivered = ({ initials }) => {
  if (!initials) return null;

  return (
    <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Delivered Date</th>
          <th>Rep</th>
          <th>Attorney</th>
          <th>name</th>
        </tr>
      </thead>
      <tbody>
        {initials.map((initial, index) => (
          <tr key={initial._id}>
            <td>{index + 1}</td>
            <td>{relativeTodayDate(initial.date)}</td>
            <td>{relativeTodayDate(initial.deliverDate)}</td>
            <td>{initial.deliverBy}</td>
            <td>{initial.attorney}</td>
            <td>{initial.name}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
