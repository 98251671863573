import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

const Header = ({ user }) => {
  const avatarUrl = `https://ui-avatars.com/api/?name=${user.fullName}`;
  return (
    <Navbar
      bg="white"
      expand="lg"
      style={{ boxShadow: "0 2px 4px 0 #00000014" }}
    >
      <Container>
        <Navbar.Brand href="">
          <img
            alt=""
            src="/logo.png"
            height="40"
            className="d-inline-block align-top"
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Link to="/" className="nav-link" style={{ color: "black" }}>
              Home
            </Link>
          </Nav>
          {user.isAdmin && (
            <>
              <Link
                to="/nearme"
                className="nav-link"
                style={{ color: "black" }}
              >
                Near Me
              </Link>
              <Link
                to="/myroute"
                className="nav-link"
                style={{ color: "black" }}
              >
                Route
              </Link>
              <Link
                to="/createroute"
                className="nav-link"
                style={{ color: "black" }}
              >
                Create Route
              </Link>
              <Link
                to="/locations/create"
                className="nav-link"
                style={{ color: "black" }}
              >
                Locations
              </Link>
              <Link
                to="/Promos/create"
                className="nav-link"
                style={{ color: "black" }}
              >
                Promos
              </Link>
              <Link to="/admin" className="nav-link" style={{ color: "black" }}>
                Admin
              </Link>
            </>
          )}
          <Nav.Link>
            <Image
              roundedCircle
              thumbnail
              src={avatarUrl}
              style={{ width: "48px" }}
            />
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
