import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import Button from "react-bootstrap/Button";

const ButtonExportExcel = ({ data, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, `${fileName}.xlsx`);
  };

  return (
    <Button variant="outline-success" onClick={exportToExcel}>
      Export to Excel
    </Button>
  );
};

export default ButtonExportExcel;
