import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { relativeDateAndShortDate } from "../utils/helpers";
import { STATUS } from "../utils/constantValues";
import axios from "axios";

const TableInitials = ({ initials, handleRefreshData }) => {
  if (!initials || initials.length === 0)
    return (
      <div className="container-row flex-center">
        <h3>Initials not found!!</h3>
      </div>
    );

  async function handleClick(e) {
    console.log(e.target.dataset.id);
    //call the api to update the initials
    const id = e.target.dataset.id;
    const today = new Date();
    const deliverDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    const data = {
      deliverDate,
      status: STATUS.delivered,
    };
    const response = await axios.put(`/api/initials/${id}`, data);
    console.log(response);
    handleRefreshData();
  }

  return (
    initials.length > 0 && (
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>Date</th>
            <th>Attorney</th>
            <th>Name</th>
            <th>Rep</th>
            <th>Created By</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {initials.map((initial) => (
            <tr key={initial._id}>
              <td>{relativeDateAndShortDate(initial.date)}</td>
              <td>{initial.attorney}</td>
              <td>{initial.name}</td>
              <td>{initial.rep}</td>
              <td>{initial.createdBy}</td>
              <td style={{ textAlign: "right" }}>
                <Button
                  variant="primary"
                  onClick={handleClick}
                  data-id={initial._id}
                  className="button button-success mr-5"
                >
                  Deliver
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};

export default TableInitials;
