import { useAccountsByLocation } from "../hooks/useAccounts";
import { Loading } from "../components/Loading";
import { TableAccounts } from "../components/TableAccounts";
import { useState } from "react";
import { LOCATIONS } from "../utils/constantValues";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import _ from "lodash";

export const AccountsByLocation = ({ user }) => {
  const [location, setLocation] = useState("SW");

  const accountsQuery = useAccountsByLocation(location);

  if (accountsQuery.isLoading) return <Loading message="Loading Accounts..." />;

  const accounts = _.orderBy(
    accountsQuery.data,
    ["status", "lastVisitDaysAgoInteger"],
    ["asc", "desc"]
  );

  return (
    <Container fluid>
      <Row>
        <Col className="mt-4 d-flex flex-column justify-content-center">
          <h2>ACCOUNTS BY LOCATION {location}</h2>
          <div className="input-group">
            <Form.Select
              className="mb-3"
              onChange={(e) => setLocation(e.target.value)}
            >
              {LOCATIONS.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>
      {accountsQuery.isSuccess && (
        <TableAccounts accounts={accounts} showDistance={false} />
      )}
    </Container>
  );
};
