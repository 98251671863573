import { useState } from "react";
import { TOUCHES } from "../../../utils/constantValues";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import { useVisitsByMainTouch } from "../../../hooks/useVisits";
import { TableVisits } from "../../../components/TableVisits";
import { Loading } from "../../../components/Loading";

const VisitByTouchReport = () => {
  const [mainTouch, setMainTouch] = useState("");
  const visitsQuery = useVisitsByMainTouch(mainTouch);
  let visits = [];

  if (visitsQuery.isLoading) return <Loading message="Loading" />;

  if (visitsQuery.isError) return <div>Error: {visitsQuery.error.message}</div>;

  if (visitsQuery.isSuccess) {
    visits = visitsQuery.data;
  }

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Visit By Touch Report</h3>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">Touch:</InputGroup.Text>
                <Form.Select
                  aria-label="Default select example"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setMainTouch(e.target.value)}
                >
                  <option>--Select Touch--</option>
                  {TOUCHES.map((touch) => (
                    <option key={touch}>{touch}</option>
                  ))}
                </Form.Select>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <TableVisits visits={visits} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default VisitByTouchReport;
