import { Link, useParams } from "react-router-dom";
import { AccountStatus } from "../components/Table/AccountStatus";
import GoogleMapReact from "google-map-react";

import Marker from "../components/Marker";
import { Loading } from "../components/Loading";
import { AccountInformation } from "../components/AccountInformation";
import { useAccount } from "../hooks/useAccounts";
import { useAccountContacts } from "../hooks/useAccountContacts";
import { TableAccountContacts } from "../components/TableAccountContacts";
import { useNotesByAccount } from "../hooks/useNotes";
import TableNotes from "../components/TableNotes";
import TableCalls from "../components/TableCalls";
import { useVisitsByAccount } from "../hooks/useVisits";
import _ from "lodash";
import { TableVisits } from "../components/TableVisits";
import TableReferrals from "../components/TableReferrals";
import { useReferralsByAccount } from "../hooks/useReferrals";
import { useCallsByAccount } from "../hooks/useCalls";
import { calculateDistance } from "../utils/helpers";

import { CHECK_IN_MIN_DISTANCE } from "../utils/constantValues";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

export const Account = ({ user, location }) => {
  const { id } = useParams();
  const accountQuery = useAccount(id);
  const contactsQuery = useAccountContacts(id);
  const notesQuery = useNotesByAccount(id);
  const visitsQuery = useVisitsByAccount(id);
  const referralsQuery = useReferralsByAccount(id);
  const callsQuery = useCallsByAccount(id);

  if (
    accountQuery.isLoading ||
    contactsQuery.isLoading ||
    notesQuery.isLoading ||
    visitsQuery.isLoading ||
    referralsQuery.isLoading ||
    callsQuery.isLoading
  ) {
    return <Loading message="Loading Account..." />;
  }
  if (
    accountQuery.isError ||
    contactsQuery.isError ||
    notesQuery.isError ||
    visitsQuery.isError ||
    referralsQuery.isError ||
    callsQuery.isError
  ) {
    return (
      <div className="container">
        <div>Error</div>
      </div>
    );
  }

  let account = accountQuery.data;
  const contacts = contactsQuery.data;
  const notes = notesQuery.data;
  const visits = _.orderBy(visitsQuery.data, ["date"], ["desc"]);
  const referrals = _.orderBy(referralsQuery.data, ["date"], ["desc"]);

  if (account) {
    //Add distance to accounts
    if (location) {
      const { latitude, longitude } = location;
      //Calculate distance
      const distance = calculateDistance(
        latitude,
        longitude,
        account.lat,
        account.lng
      );
      account = { ...account, distance };
    }
  }

  return (
    <Container className="mt-4 margin-container-bottom">
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <h2>
                  {account.name} <AccountStatus status={account.statusV2} />
                </h2>

                <div>
                  {account.distance <= CHECK_IN_MIN_DISTANCE && (
                    <Link to={`/checkin/${account._id}`}>Check In</Link>
                  )}
                  <Link to={`/admin/account/editAccountAddress/${account._id}`}>
                    Edit Address
                  </Link>
                </div>
              </div>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <AccountInformation account={account} />
                </Col>
                <Col>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                    }}
                    defaultCenter={{
                      lat: account.lat,
                      lng: account.lng,
                    }}
                    defaultZoom={11}
                  >
                    <Marker
                      text={account.name}
                      lat={account.lat}
                      lng={account.lng}
                    />
                  </GoogleMapReact>
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <h2>Referrals</h2>
                  <TableReferrals referrals={referrals} />
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <h2>Visits</h2>
                  <TableVisits visits={visits} />
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <div className="d-flex justify-content-between">
                    <h2>Contacts</h2>
                    <Link
                      to={`/contacts/addcontact/${account._id}`}
                      className="button button-info m-10"
                    >
                      Create Contact
                    </Link>
                  </div>

                  <TableAccountContacts
                    contacts={contacts}
                    isAdmin={user.isAdmin}
                  />
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <div className="d-flex justify-content-between">
                    <h2 className="m-10 p-5">Notes</h2>
                    <Link
                      to={`/notes/createaccount/${account._id}`}
                      className="button button-info m-10"
                    >
                      Create Note
                    </Link>
                  </div>

                  <TableNotes notes={notes} showButtons={false} />
                </Col>
              </Row>
              <hr></hr>
              <Row>
                <Col>
                  <Card className="mt-2">
                    <Card.Body>
                      {callsQuery.isSuccess && (
                        <>
                          <Row>
                            <Col>
                              <h4>Qc-Calls</h4>
                            </Col>
                          </Row>
                          <TableCalls calls={callsQuery.data} />
                        </>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
