import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const HomeReports = () => {
  const navigate = useNavigate();

  const checkInClicked = () => {
    navigate("/admin/reports/checkins");
  };
  const routeClicked = () => {
    navigate("/admin/reports/routes");
  };
  const zoneClicked = () => {
    navigate("/admin/reports/zone");
  };
  const visitsByTouchClicked = () => {
    navigate("/admin/reports/visitsByTouch");
  };
  const qcCallsTouchClicked = () => {
    navigate("/admin/reports/qcCalls");
  };

  const PromoClicked = () => {
    navigate("/admin/reports/promos");
  };

  const InitialDeliveredClicked = () => {
    navigate("/admin/reports/initials");
  };
  return (
    <Container className="mt-4" style={{ marginBottom: "200px" }}>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Reports</h3>
            <Link to="/admin/">Admin</Link>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <ListGroup>
                <ListGroup.Item action onClick={checkInClicked}>
                  Check-In
                </ListGroup.Item>
                <ListGroup.Item action onClick={routeClicked}>
                  Route
                </ListGroup.Item>
                <ListGroup.Item action onClick={zoneClicked}>
                  Zone
                </ListGroup.Item>
                <ListGroup.Item action onClick={visitsByTouchClicked}>
                  Visits By Touch
                </ListGroup.Item>
                <ListGroup.Item action onClick={qcCallsTouchClicked}>
                  QC Calls
                </ListGroup.Item>
                <ListGroup.Item action onClick={PromoClicked}>
                  Promo
                </ListGroup.Item>
                <ListGroup.Item action onClick={InitialDeliveredClicked}>
                  Initials Delivered
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HomeReports;
