import { useProspectAccounts } from "../hooks/useAccounts";
import _ from "lodash";
import { Loading } from "../components/Loading";
import { TableAccounts } from "../components/TableAccounts";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ButtonExportExcel from "../components/ButtonExportExcel";

const ProspectAccounts = ({ user }) => {
  const [accountType, setAccountType] = useState("all");

  const accountsQuery = useProspectAccounts();

  if (accountsQuery.isLoading) return <Loading message="Loading Accounts..." />;

  let accounts = accountsQuery.data;

  if (accountType !== "all") {
    accounts = accounts.filter(
      (account) => account.type === accountType.toUpperCase()
    );
  }

  accounts = _.orderBy(
    accounts,
    ["status", "lastVisitDaysAgoInteger"],
    ["asc", "desc"]
  );

  return (
    <Container fluid className="mt-2">
      <Card>
        <Card.Header>
          <Row>
            <Col className="d-flex justify-content-between">
              <h1>PROSPECT ACCOUNTS - {accountType.toUpperCase()}</h1>
              <div>
                <Button
                  variant="outline-primary"
                  onClick={() => setAccountType("all")}
                >
                  All
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  onClick={() => setAccountType("medical")}
                >
                  Medical
                </Button>{" "}
                <Button
                  variant="outline-primary"
                  onClick={() => setAccountType("legal")}
                >
                  Legal
                </Button>
                {user && user.isAdmin && (
                  <ButtonExportExcel
                    data={accounts}
                    fileName="ProspectAccounts"
                  />
                )}
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {accountsQuery.isSuccess && (
                <TableAccounts
                  accounts={accounts}
                  showDistance={false}
                  showAllInformation={true}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ProspectAccounts;
