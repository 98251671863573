import { useState } from "react";
import { useForm } from "react-hook-form";
import { usePromoByMonthYearAndRep } from "../../hooks/usePromo";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/esm/Form";
import { Loading } from "../../components/Loading";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import CardsPromo from "../../components/CardsPromo";

const PromoCreate = ({ user }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [queryDate, setQueryDate] = useState({
    month: null,
    year: null,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const promoQuery = usePromoByMonthYearAndRep(
    queryDate.month,
    queryDate.year,
    user.localUsername
  );

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.rep = user.localUsername;

      //call axios create promo
      const res = await axios.post("/api/promos", data);
      if (res.status === 201) {
        setIsLoading(false);
        promoQuery.refetch();
      }
    } catch (err) {
      console.log(err);
      return alert("Error creating promo");
    }
  };

  if (isLoading || promoQuery.isLoading)
    return <Loading message="Creating Promo" />;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <h2>Create Promo</h2>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-2">
              <Col md={4} sm={12}>
                <Form.Group>
                  <Form.Label>Date:*</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="date"
                    {...register("date", {
                      required: true,
                      onChange: (e) => {
                        const date = new Date(e.target.value);
                        const month = date.getMonth() + 1;
                        const year = date.getFullYear();
                        setQueryDate({ month, year });
                      },
                    })}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col md={4} sm={12}>
                <Form.Group>
                  <Form.Label>Promo Name:*</Form.Label>
                  <Form.Control
                    type="text"
                    maxlength={20}
                    placeholder="Promo name"
                    {...register("name", { required: true, maxLength: 20 })}
                    size="lg"
                  />
                </Form.Group>
                {errors.promo && errors.promo.type === "maxLength" && (
                  <span>Max length exceeded</span>
                )}
              </Col>

              <Col md={4} sm={12}>
                <Form.Group>
                  <Form.Label>Budget by Each Promo</Form.Label>
                  <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="55.00"
                    {...register("budget")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button type="submit" variant="primary" size="lg">
                  Create Promo
                </Button>
              </Col>
            </Row>
          </Form>
          <hr></hr>
          <Row>
            <Col>
              {promoQuery.data && <CardsPromo promosQuery={promoQuery} />}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PromoCreate;
