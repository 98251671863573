import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useAccountContact } from "../../hooks/useAccountContacts";
import { Link } from "react-router-dom";
import { PhoneInput } from "../../components/inputs/PhoneInput";
import axios from "axios";
import { BackIcon, EditIcon } from "../../components/Icons";

import { inputDateFormat } from "../../utils/dates";

export const EditContact = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formValues, setFormValues] = React.useState({
    name: "",
    birthday: "",
    email: "",
    phone: "",
    position: "",
    notes: "",
    AccountId: "",
  });
  const contactQuery = useAccountContact(id);

  useEffect(() => {
    if (contactQuery.data) {
      setFormValues({
        name: contactQuery.data.name,
        birthday: inputDateFormat(contactQuery.data.birthday),
        email: contactQuery.data.email,
        phone: contactQuery.data.phone,
        position: contactQuery.data.position,
        notes: contactQuery.data.notes,
        AccountId: contactQuery.data.AccountId,
      });
    }
  }, [contactQuery.data]);

  async function handleSubmit(event) {
    event.preventDefault();
    //Validate  values formValues
    if (!formValues.name) return alert("Name is required");
    if (!formValues.position) return alert("Position is required");

    //Call api using axios
    const response = await axios.put(`/api/account-contacts/${id}`, formValues);
    if (response.status === 200) {
      //Redirect to account page
      navigate(`/account/${contact.AccountId._id}`);
    }
  }

  function handleInputChange(event) {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  }

  if (contactQuery.isLoading) {
    return <Loading message="Loading Account Information..." />;
  }

  const contact = contactQuery.data;

  return (
    <div className="container-base mb-50">
      <div className="container-row flex-end mt-10">
        <Link
          to={`/account/${contact.AccountId._id}`}
          className="button button-info"
        >
          Back
        </Link>
      </div>
      <div className="container-row">
        <div className="white-card text-card flex-column mt-10">
          <div className="container-row mt-20">
            <h1>Add Contact {contact.AccountId.name}</h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                placeholder="Contact Name"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="position">Position</label>
              <input
                type="text"
                placeholder="Position"
                name="position"
                value={formValues.position}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="birthday">Birthday</label>
              <input
                type="date"
                placeholder="Birthday"
                name="birthday"
                value={formValues.birthday}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="phone">Phone</label>
              <PhoneInput
                type="phone"
                placeholder="Phone"
                name="phone"
                value={formValues.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              <label htmlFor="notes">Notes</label>
              <input
                type="text"
                placeholder="Notes"
                name="notes"
                value={formValues.notes}
                onChange={handleInputChange}
              />
            </div>
            <div className="container-row mt-10 mb-40">
              <button type="submit" className="button button-warning">
                <EditIcon /> Edit Contact
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
