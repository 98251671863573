import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { TableAccounts } from "../components/TableAccounts";
import { Loading } from "../components/Loading";
import Container from "react-bootstrap/Container";

export const NearMe = ({ user }) => {
  const [location, setLocation] = useState(null);

  //get accounts near me
  function requestAccountsNearMe() {
    const { latitude, longitude } = location.coords;
    return axios.get(`/api/accounts/nearme/${latitude}/${longitude}`);
  }

  //get user location
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLocation(position);
    });
  }, [location]);

  const { data, isLoading, error, isSuccess } = useQuery(
    "accountsNearMe",
    requestAccountsNearMe,
    {
      enabled: !!location,
    }
  );

  if (!location) {
    return <Loading message="Loading Accounts Near Me" />;
  }

  if (isLoading) {
    return <Loading message="Loading Accounts Near Me" />;
  }
  return (
    <Container fluid>
      <div className="d-flex justify-content-center my-3">
        <h2>ACCOUNTS NEAR ME</h2>
      </div>
      {isSuccess && (
        <TableAccounts
          accounts={data.data}
          showDistance={true}
          showAllInformation={true}
        />
      )}
    </Container>
  );
};

export default NearMe;
