import { useParams, useNavigate } from "react-router-dom";
import { useVisitById } from "../../hooks/useVisits";
import { Loading } from "../../components/Loading";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { DeleteIcon, BackIcon } from "../../components/Icons";
import { TOUCHES } from "../../utils/constantValues";

const DeleteVisit = ({ user }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const visitQuery = useVisitById(id);

  if (visitQuery.isLoading) return <Loading message="Loading Information..." />;

  const visit = visitQuery.data;

  async function handleSubmit(event) {
    event.preventDefault();
    //Call api using axios
    const response = await axios.delete(`/api/visits/${visit._id}`);
    if (response.status === 200) {
      //Redirect to Todays Visits
      navigate(`/visits/today/`);
    }
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h1>Visit {visit.account.name}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-3">
              <Form.Select name="mainTouch" value={visit.mainTouch} disabled>
                {TOUCHES.map((touch) => (
                  <option key={touch} value={touch}>
                    {touch}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Select name="touch" value={visit.touch} disabled>
                <option value="HOT">🔥 HOT</option>
                <option value="WARM">🌤 WARM</option>
                <option value="COLD">🥶 COLD</option>
              </Form.Select>
            </Form.Group>
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Note"
              className="mt-3"
            >
              <Form.Control
                as="textarea"
                placeholder="Leave a note here"
                name="notes"
                value={visit.notes}
                readOnly
                style={{ height: "100px" }}
              />
            </FloatingLabel>
            <div className="d-flex justify-content-center mt-2">
              <Button type="submit" variant="danger" size="lg">
                <DeleteIcon /> Delete
              </Button>
              <Button
                className="ms-2"
                variant="primary"
                size="lg"
                onClick={() => navigate("/visits/today")}
              >
                <BackIcon /> Back
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default DeleteVisit;
