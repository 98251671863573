import { useCallback } from "react";
import axios from "axios";
import _ from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { Loading } from "../components/Loading";
import { useAccountsRepAndQr, useSearchAccounts } from "../hooks/useAccounts";
import { useRouteByDate } from "../hooks/useRoutes";
import { useRepActives } from "../hooks/useUser";
import { SearchIcon } from "../components/Icons";
import {
  QUADRANT1,
  QUADRANT2,
  MAX_ROUTE_POINTS,
} from "../utils/constantValues";

import RouteCard from "../components/RouteCard";
import AccountsList from "../components/AccountsList";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//format Date in yyyy-mm-dd
const formatDate = (date) => {
  const dd = String(date.getDate()).padStart(2, "0");
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0!
  const yyyy = date.getFullYear();

  return `${yyyy}-${mm}-${dd}`;
};

//get quadrants odd or even based in week number
const getRecommendedQuadrant = (date) => {
  const weekNumber = DateTime.fromISO(date).weekNumber;
  const quadrantRecommended = weekNumber % 2;
  return quadrantRecommended + 1;
};

//Get Monday of this week
// Create a function outside the component
const getMondayOfTheWeek = (date) => {
  return DateTime.fromObject({
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  }).startOf("week");
};

const CreateRoute = ({ user }) => {
  const [formValues, setFormValues] = useState({
    rep: "",
    date: "",
    searchAccount: "",
  });
  const [dateMinValue, setDateMinValue] = useState("");
  const [dateMaxValue, setDateMaxValue] = useState("");
  const [selectedQuadrant, setSelectedQuadrant] = useState("");
  const [quadrantRecommended, setQuadrantRecommended] = useState("");
  const [searchAccount, setSearchAccount] = useState("");
  const [routes, setRoutes] = useState([]);
  const [accounts, setAccounts] = useState([]);

  //query Active reps
  const repQuery = useRepActives();
  const accountsRepAndQr = useAccountsRepAndQr(
    formValues.rep,
    selectedQuadrant
  );
  const accountsSearcByTermQuery = useSearchAccounts(searchAccount);
  //Get accounts in route by date and rep
  const routesQuery = useRouteByDate(formValues.rep, formValues.date);

  //assign todays date to formValues.date useEffect
  useEffect(() => {
    const today = new Date();
    //Get Monday of this week
    const mondayThisWeek = getMondayOfTheWeek(today);
    //today add 2 weeks
    const todayPlus2Weeks = DateTime.fromJSDate(today).plus({ weeks: 2 });
    //Get Sunday of todayPlus2Weeks
    const sundayPlus2Weeks = DateTime.fromJSDate(
      todayPlus2Weeks.toJSDate()
    ).endOf("week");

    //Set dateMinValue and dateMaxValue
    setDateMinValue(formatDate(mondayThisWeek.toJSDate()));
    setDateMaxValue(formatDate(sundayPlus2Weeks.toJSDate()));

    //set quadrantRecommended
    setQuadrantRecommended(getRecommendedQuadrant(formatDate(today)));

    if (routesQuery.status === "success") {
      console.log("accounts in route", routesQuery.data);

      // Update routes
      setRoutes(routesQuery.data);

      // Extract the accounts from the routes
      const routeAccounts = routesQuery.data.map((route) => route.account);

      // If search term is provided and search query is successful, use search results
      // else if accountsRepAndQr query is successful, use its data
      let accountsData = [];
      if (accountsSearcByTermQuery.status === "success") {
        console.log("set Accounts from search");
        accountsData = accountsSearcByTermQuery.data;
      } else if (accountsRepAndQr.status === "success") {
        accountsData = accountsRepAndQr.data;
      }

      // Filter accounts not in the route
      const filteredAccounts = _.differenceBy(
        accountsData,
        routeAccounts,
        "_id"
      );

      setAccounts(filteredAccounts);
    }
  }, [
    routesQuery.status,
    routesQuery.data,
    accountsRepAndQr.status,
    accountsRepAndQr.data,
    accountsSearcByTermQuery.status,
    accountsSearcByTermQuery.data,
  ]);

  function handleInputChange(event) {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === "date") {
      //Set quadrantRecommended
      const weekNumber = DateTime.fromISO(event.target.value).weekNumber;
      const quadrantRecommended = weekNumber % 2;
      setQuadrantRecommended(quadrantRecommended + 1);
    }
  }

  // Reset form values
  const resetSearchAccount = () => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      searchAccount: "",
    }));
    setSearchAccount("");
  };

  //Filter Quadrant
  function buttonQuadrantClick(quadrant) {
    //Clear searchAccount
    resetSearchAccount();
    //Set Quadrant
    setSelectedQuadrant(quadrant);
  }

  // this function finds accounts with the same address as the selected account
  const getAccountsWithSameAddress = (account, accountsRepAndQr) => {
    if (!accountsRepAndQr.data) return [];

    return accountsRepAndQr.data.filter(
      (accountRepAndQr) =>
        accountRepAndQr.addressLine1.trim() === account.addressLine1.trim() &&
        accountRepAndQr.zip === account.zip &&
        accountRepAndQr._id !== account._id
    );
  };

  // this function handles the logic of selecting which accounts to add
  const selectAccountsToAdd = (
    account,
    placesInRoute,
    accountsWithSameAddress
  ) => {
    let accountsToAdd = [];

    if (placesInRoute > accountsWithSameAddress.length) {
      accountsToAdd = [account, ...accountsWithSameAddress];
    } else if (placesInRoute > 0) {
      accountsWithSameAddress = accountsWithSameAddress.slice(
        0,
        placesInRoute - 1
      );
      accountsToAdd = [account, ...accountsWithSameAddress];
    }

    return accountsToAdd;
  };

  // this function creates a route and sends it to the server
  const createRoute = async (formValues, accountsToAdd) => {
    const data = {
      date: formValues.date,
      accounts: accountsToAdd.map((account) => account._id),
      rep: formValues.rep,
    };

    const res = await axios.post("/api/routes/create-route", data);

    return res;
  };

  // this function handles the logic of adding accounts to the route`
  const handleClickAdd = useCallback(
    async (account) => {
      if (routes.length >= MAX_ROUTE_POINTS) {
        return alert(`Max ${MAX_ROUTE_POINTS} accounts in route`);
      }

      let placesInRoute = MAX_ROUTE_POINTS - routes.length;
      const accountsWithSameAddress = getAccountsWithSameAddress(
        account,
        accountsRepAndQr
      );
      const accountsToAdd = selectAccountsToAdd(
        account,
        placesInRoute,
        accountsWithSameAddress
      );

      if (accountsWithSameAddress.length > 0) {
        alert(
          "Accounts to add with the same address: " +
            accountsWithSameAddress.length
        );
      }
      const res = await createRoute(formValues, accountsToAdd);

      if (res.status === 200) {
        console.log("accountsAdded", accountsToAdd);
        // Display toast message for each account added
        accountsToAdd.forEach((acc) => {
          toast.success(`${acc.name} added`, {
            autoClose: 2000,
          });
        });

        //Remove from Accounts and Add in Accounts In Route
        routesQuery.refetch();
      }
    },
    [accountsRepAndQr, formValues, routes.length, routesQuery]
  );

  //Remove Item from the route
  const handleClickRemove = useCallback(async (routeToRemove) => {
    // Send DELETE request to server
    try {
      const response = await axios.delete(`/api/routes/${routeToRemove._id}`);
      console.log("remove response", response);

      // handle the response as needed, e.g. show a notification or error message
      if (response.status === 200) {
        console.log(
          "Route successfully deleted from the database.",
          routeToRemove
        );
        toast.success(`account ${routeToRemove.account.name}  removed `, {
          autoClose: 2000,
        });

        // Remove from local state
        setRoutes((prevRoutes) =>
          prevRoutes.filter((route) => route._id !== routeToRemove._id)
        );

        // Add the removed account back to the accounts
        setAccounts((prevAccounts) => [...prevAccounts, routeToRemove.account]);
      } else {
        //add toast error
        toast.error(`Failed to delete route from the database.`, {
          autoClose: 2000,
        });

        console.error("Failed to delete route from the database.");
      }
    } catch (error) {
      //add toast error
      toast.error(`Failed to delete route from the database.`, {
        autoClose: 2000,
      });
      console.error("Failed to delete route from the database.", error);
    }
  }, []);

  //Handle Search Account
  const handleSearch = () => {
    console.log("searchAccount", formValues.searchAccount);

    setSearchAccount(formValues.searchAccount);
  };

  if (repQuery.isLoading) {
    return <Loading message="Loading Reps..." />;
  }

  if (accountsRepAndQr.isLoading || accountsSearcByTermQuery.isLoading) {
    return <Loading message="Loading Accounts..." />;
  }

  if (routesQuery.isLoading) {
    return <Loading message="Loading Routes..." />;
  }

  let reps = repQuery.data;
  //if user is not admin, filter reps by user
  if (reps) {
    if (!user.isAdmin) {
      reps = reps.filter((rep) => rep._id === user._id);
    }
  }

  return (
    <Container className="mt-4 margin-container-bottom">
      <ToastContainer />
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>Create Route Quadrant {quadrantRecommended}</h3>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={6} sm={12} className="mb-1">
                  <Form.Control
                    size="lg"
                    as="input"
                    type="date"
                    name="date"
                    min={dateMinValue}
                    max={dateMaxValue}
                    value={formValues.date}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col md={6} sm={12} className="mb-1">
                  <Form.Control
                    as="select"
                    size="lg"
                    name="rep"
                    value={formValues.rep}
                    onChange={handleInputChange}
                  >
                    <option value="">Select Rep</option>
                    {reps.map((rep) => (
                      <option key={rep._id} value={rep.localUsername}>
                        {rep.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Row className="mt-2">
                    <Col>
                      <Card>
                        <Card.Body>
                          {formValues.rep && formValues.date && (
                            <Row>
                              <Col>
                                <InputGroup className="mb-3">
                                  <Form.Control
                                    placeholder="Search Account by name or zipcode"
                                    aria-label="Search Account"
                                    aria-describedby="Searc account"
                                    name="searchAccount"
                                    value={formValues.searchAccount}
                                    onChange={handleInputChange}
                                    size="lg"
                                  />
                                  <Button
                                    variant="outline-secondary"
                                    id="btnSearchAccount"
                                    onClick={handleSearch}
                                  >
                                    <SearchIcon /> Search
                                  </Button>
                                </InputGroup>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col>
                              <div className={`container-zone-buttons`}>
                                {formValues.rep &&
                                  QUADRANT1.map((quadrant) => (
                                    <Button
                                      variant={`${
                                        quadrantRecommended === 1
                                          ? "success"
                                          : "secondary"
                                      }`}
                                      size="lg"
                                      key={quadrant}
                                      onClick={() =>
                                        buttonQuadrantClick(quadrant)
                                      }
                                    >
                                      {quadrant}
                                    </Button>
                                  ))}
                              </div>
                              <div className={`container-zone-buttons mt-4`}>
                                {formValues.rep &&
                                  QUADRANT2.map((quadrant) => (
                                    <Button
                                      variant={`${
                                        quadrantRecommended === 2
                                          ? "success"
                                          : "secondary"
                                      }`}
                                      size="lg"
                                      key={quadrant}
                                      onClick={() =>
                                        buttonQuadrantClick(quadrant)
                                      }
                                    >
                                      {quadrant}
                                    </Button>
                                  ))}
                              </div>
                            </Col>
                          </Row>
                          <hr className="mt-4"></hr>
                          <Row>
                            <Col className="d-flex justify-content-center my-2">
                              <h1 className="mt-2">{selectedQuadrant}</h1>
                            </Col>
                          </Row>
                          <Row>
                            <AccountsList
                              accounts={accounts}
                              handleClickAdd={handleClickAdd}
                            />
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <RouteCard
                    routes={routes}
                    date={formValues.date}
                    onRemove={handleClickRemove}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateRoute;
