import { useParams, useNavigate, Link } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useNoteById } from "../../hooks/useNotes";
import { BackIcon, DeleteIcon } from "../../components/Icons";
import axios from "axios";
import Container from "react-bootstrap//Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const DeleteNote = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const noteQuery = useNoteById(id);

  if (noteQuery.isLoading) return <Loading message="Loading note..." />;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await axios.delete(`/api/notes/${id}`);
    console.log(response);
    if (response.status === 200) {
      navigate("/notes");
    }
  };

  const note = noteQuery.data;

  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-between">
          <h1>Delete Note</h1>
          <Link to={`/notes`} className="button button-info">
            <BackIcon /> Back
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <FloatingLabel controlId="floatingTextareaNote" label="Note">
              <Form.Control
                as="textarea"
                placeholder="Type your note here"
                style={{ height: "100px" }}
                name="note"
                readOnly
                value={note.note}
              />
            </FloatingLabel>

            <div className="d-flex justify-content-center mt-4">
              <Button type="submit" variant="danger" size="lg">
                <DeleteIcon /> Delete
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default DeleteNote;
