import { useState } from "react";
import { Loading } from "../../components/Loading";
import { TableVisits } from "../../components/TableVisits";
import { useCheckInsByFromTo } from "../../hooks/useCheckIns";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { SearchIcon } from "../../components/Icons";
import _ from "lodash";

const ReportVisit = ({ user }) => {
  //Today input format
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  const todayFormatted = yyyy + "-" + mm + "-" + dd;
  let visits = [];

  const [query, setQuery] = useState({
    from: todayFormatted,
    to: todayFormatted,
  });
  const checkInsQuery = useCheckInsByFromTo(
    user.localUsername,
    query.from,
    query.to
  );

  const onHandleChange = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(query);
  };

  if (checkInsQuery.isLoading) {
    return <Loading message="Loading..." />;
  }

  if (checkInsQuery.isSuccess) {
    console.log(checkInsQuery.data);
    //Order visits by checkin date lodash
    visits = _.orderBy(checkInsQuery.data, ["checkInDate"], ["asc"]);
  }

  return (
    <Container fluid className="mt-4" style={{ marginBottom: "200px" }}>
      <Card>
        <Card.Header>
          <Row>
            <Col>
              <Form onSubmit={handleClick}>
                <Row>
                  <Col>
                    <InputGroup className="me-2">
                      <InputGroup.Text id="basic-addon1">From:</InputGroup.Text>
                      <Form.Control
                        type="date"
                        aria-label="From"
                        aria-describedby="basic-addon1"
                        size="lg"
                        name="from"
                        value={query.from}
                        onChange={onHandleChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup className="me-2">
                      <InputGroup.Text id="basic-addon2">To:</InputGroup.Text>
                      <Form.Control
                        type="date"
                        aria-label="To:"
                        aria-describedby="basic-addon2"
                        size="lg"
                        name="to"
                        value={query.to}
                        onChange={onHandleChange}
                      />
                    </InputGroup>
                  </Col>
                  <Col>
                    <Button variant="primary">
                      <SearchIcon /> Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <TableVisits visits={visits} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ReportVisit;
