import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useAccount } from "../../../hooks/useAccounts";
import { useRepActives } from "../../../hooks/useUser";
import { Loading } from "../../../components/Loading";
import axios from "axios";

const EditAccount = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const accountQuery = useAccount(id);
  const repsQuery = useRepActives();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      //Add axios call to update account
      const res = await axios.put(`/api/accounts/${id}`, data);
      console.log(res);
      if (res.status === 200) {
        navigate("/admin/accounts");
      }
    } catch (err) {
      console.log(err);
      return alert("Error updating account");
    }
  };

  if (accountQuery.isLoading || repsQuery.isLoading) {
    return <Loading message="Loading..." />;
  }

  const account = accountQuery.data;
  const reps = repsQuery.data;

  return (
    <Container className="mt-4 margin-container-bottom ">
      <Card>
        <Card.Header>
          <h3>Edit Account {account.name}</h3>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Account Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter account name"
                    defaultValue={account.name}
                    {...register("name", { required: true })}
                    size="lg"
                    isValid={!errors.name}
                    isInvalid={errors.name}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Rep</Form.Label>
                  <Form.Select
                    aria-label="select account status"
                    defaultValue={account.rep}
                    {...register("rep")}
                    size="lg"
                  >
                    {repsQuery.isSuccess &&
                      reps.map((rep) => (
                        <option key={rep._id} value={rep.localUsername}>
                          {rep.fullName}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    aria-label="select account status"
                    defaultValue={account.statusV2}
                    {...register("status")}
                    size="lg"
                  >
                    <option value="PROSPECT">PROSPECT</option>
                    <option value="ACTIVE">ACTIVE</option>
                    <option value="INACTIVE">INACTIVE</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Phone:</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    placeholder="Enter phone number"
                    defaultValue={account.phone}
                    {...register("phone")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Fax:</Form.Label>
                  <Form.Control
                    type="text"
                    name="fax"
                    placeholder="Enter fax number"
                    defaultValue={account.fax}
                    {...register("fax")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter email"
                    defaultValue={account.email}
                    {...register("email")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Website:</Form.Label>
                  <Form.Control
                    type="text"
                    name="web"
                    placeholder="Enter website"
                    defaultValue={account.web}
                    {...register("web")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Type:</Form.Label>
                  <Form.Select
                    aria-label="select account status"
                    defaultValue={account.type}
                    {...register("type")}
                    size="lg"
                  >
                    <option value="MEDICAL">MEDICAL</option>
                    <option value="LEGAL">LEGAL</option>
                    <option value="OTHER">OTHER</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Freq:</Form.Label>
                  <Form.Control
                    type="text"
                    name="frequency"
                    placeholder="Enter frequency"
                    defaultValue={account.frequency}
                    {...register("frequency")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Closed:</Form.Label>
                  <Form.Select
                    aria-label="select account status"
                    defaultValue={account.isClosed}
                    {...register("isClosed")}
                    size="lg"
                  >
                    <option value="false">OPEN</option>
                    <option value="true">CLOSED</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col xs={12} md={3}>
                <Form.Group>
                  <Form.Label>Active</Form.Label>
                  <Form.Select
                    aria-label="select account status"
                    defaultValue={account.isActive}
                    {...register("isActive")}
                    size="lg"
                  >
                    <option value="false">CCIC INACTIVE</option>
                    <option value="true">CCIC ACTIVE</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button type="submit" variant="primary" size="lg">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditAccount;
