import { useParams, useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Loading } from "../../components/Loading";
import { useNoteById } from "../../hooks/useNotes";
import axios from "axios";
import { BackIcon, EditIcon } from "../../components/Icons";
import Container from "react-bootstrap//Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const EditNote = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const noteQuery = useNoteById(id);

  const { register, handleSubmit } = useForm();

  if (noteQuery.isLoading) return <Loading message="Loading note..." />;

  const onSubmit = async (data) => {
    if (!data.note) {
      return alert("Note is required");
    }
    const response = await axios.put(`/api/notes/${id}`, data);
    if (response.status === 200) {
      navigate("/notes");
    }
  };

  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-between">
          <h1>Edit Note</h1>
          <Link to={`/notes`} className="button button-info">
            <BackIcon /> Back
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FloatingLabel controlId="floatingTextareaNote" label="Note">
              <Form.Control
                as="textarea"
                placeholder="Type your note here"
                style={{ height: "100px" }}
                defaultValue={noteQuery.data.note}
                {...register("note", { required: true })}
              />
            </FloatingLabel>

            <div className="d-flex justify-content-center mt-4">
              <Button type="submit" variant="warning" size="lg">
                <EditIcon /> Edit Note
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditNote;
