import React from "react";
import { FaSignature, FaUserMd, FaRegCommentDots } from "react-icons/fa";
import Badge from "react-bootstrap/Badge";

export const AccountType = ({ accountType }) => {
  if (accountType === "LEGAL") {
    return (
      <Badge bg="info">
        <FaSignature />
        <span className="ml-2">{accountType}</span>
      </Badge>
    );
  }
  if (accountType === "MEDICAL") {
    return (
      <Badge bg="primary">
        <FaUserMd />
        <span className="ml-2">{accountType}</span>
      </Badge>
    );
  }
  return (
    <Badge bg="warning">
      <FaRegCommentDots className="color-white" />
      <span className="ml-2">{accountType}</span>
    </Badge>
  );
};
