import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAccount } from "../../hooks/useAccounts";
import { BackIcon, NewIcon } from "../../components/Icons";
import Container from "react-bootstrap//Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { todayInputFormat, nowTime } from "../../utils/dates";
import Card from "react-bootstrap/Card";
import { Loading } from "../../components/Loading";

const CreateCall = () => {
  const navigate = useNavigate();
  const { accountId } = useParams();
  const accountQuery = useAccount(accountId);
  const todayInput = todayInputFormat();

  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    try {
      //Validate data before sending
      if (data.date === "") {
        return alert("Please enter a date");
      }
      if (!data.note) {
        return alert("Please enter a valid note");
      }
      data.account = accountId;

      const time = nowTime();
      //Add time to data date
      data.date = `${data.date}T${time}`;

      //post data to server
      const res = await axios.post(`/api/calls/`, data);

      if (res.status === 201) {
        navigate("/qcCalls/today");
      }
    } catch (err) {
      console.log(err);
      return alert(err);
    }
  };

  if (accountQuery.isLoading) return <Loading message="Loading..." />;

  const account = accountQuery.data;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <Row>
            <Col className="d-flex justify-content-between">
              <h3>Create Qc-Call</h3>
              <Link to={`/search`} className="button button-info">
                <BackIcon /> Back
              </Link>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <h5>{account.name}</h5>
                      <span>Phone:{account.phone}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-3">
                  <Col xs={12}>
                    <Form.Group>
                      <Form.Label>Date:</Form.Label>
                      <Form.Control
                        type="date"
                        {...register("date", { required: true })}
                        defaultValue={todayInput}
                        size="lg"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <FloatingLabel
                      controlId="textareaNote"
                      label="Qc-Call Note"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Qc-Call Note"
                        style={{ height: "100px" }}
                        name="note"
                        {...register("note", { required: true })}
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Report to Technician"
                      name="reportToTechnician"
                      {...register("reportToTechnician", { required: false })}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Report to Marketing Rep"
                      name="reportToMarketingRep"
                      {...register("reportToMarketingRep", { required: false })}
                    />
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <Button type="submit" variant="primary" size="lg">
                      <NewIcon /> Create Qc-Call
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateCall;
