import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Login = ({ user }) => {
  const navigate = useNavigate();
  const signInWithMicrosoft = () => {
    //redirecto to microsoft login
    //window.location.href = "http://localhost:3001/api/auth/azureadoauth2";
    window.location.href = `api/auth/azureadoauth2`;
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <div className="container-login">
      <img
        src="sign-microsoft.svg"
        alt="Microsoft"
        className="img-microsoft"
        onClick={signInWithMicrosoft}
      />
    </div>
  );
};

export default Login;
