import { Link } from "react-router-dom";
import { useCallsByDateRange } from "../../hooks/useCalls";
import { BackIcon } from "../../components/Icons";
import Container from "react-bootstrap//Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { todayInputFormat } from "../../utils/dates";
import Card from "react-bootstrap/Card";
import { Loading } from "../../components/Loading";
import TableCalls from "../../components/TableCalls";

const TodayCalls = ({ user }) => {
  const todayFrom = todayInputFormat();

  const callsQuery = useCallsByDateRange(todayFrom, todayFrom);

  if (callsQuery.isLoading) return <Loading message="Loading Qc-Calls..." />;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <Row>
            <Col className="d-flex justify-content-between">
              <h3>Today's Qc-Call</h3>
              <Link to={`/`}>
                <BackIcon /> Back
              </Link>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {callsQuery.isSuccess && (
                <TableCalls
                  calls={callsQuery.data}
                  showButtons={true}
                  rep={user.localUsername}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TodayCalls;
