import { useQuery } from "react-query";
import axios from "axios";

//GET visits by account
export function useVisitsByAccount(accountId) {
  const query = useQuery(
    ["visits", "account", accountId],
    async () => {
      const res = await axios.get(`/api/visits/account/${accountId}/`);
      return res.data;
    },
    {
      enabled: !!accountId,
    }
  );
  return query;
}

//GET visits by account
export function useVisitsByAccountWithLimit(accountId, limit) {
  const query = useQuery(
    ["visits", "account", `limit-${limit}`, accountId],
    async () => {
      const res = await axios.get(
        `/api/visits/account-limit/${accountId}/${limit}`
      );
      return res.data;
    },
    {
      enabled: !!accountId,
    }
  );
  return query;
}

//GET visit by id
export function useVisitById(visitId) {
  const query = useQuery(
    ["visits", "id", visitId],
    async () => {
      const res = await axios.get(`/api/visits/${visitId}/`);
      return res.data;
    },
    {
      enabled: !!visitId,
    }
  );
  return query;
}

//GET visits by mainTouch
export function useVisitsByMainTouch(mainTouch) {
  const query = useQuery(
    ["visits", "mainTouch", mainTouch],
    async () => {
      const res = await axios.get(`/api/visits/mainTouch/${mainTouch}/`);
      return res.data;
    },
    {
      enabled: !!mainTouch,
    }
  );
  return query;
}

//GET  vistis by Date  rep
export function useVisitsByDateRep(rep, from, to) {
  const query = useQuery(
    ["visits", "date", rep, from, to],
    async () => {
      const res = await axios.get(`/api/visits/dates-rep/${rep}/${from}/${to}`);
      return res.data;
    },
    {
      enabled: !!rep && !!from && !!to,
    }
  );
  return query;
}

//GET  vistis by Dates
export function useVisitsByDates(from, to) {
  const query = useQuery(
    ["visits", "date", from, to],
    async () => {
      const res = await axios.get(`/api/visits/dates/${from}/${to}`);
      return res.data;
    },
    {
      enabled: !!from && !!to,
    }
  );
  return query;
}
