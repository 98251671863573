import Badge from "react-bootstrap/Badge";

export const AccountStatus = ({ status }) => {
  if (status === "ACTIVE") {
    return <Badge bg="success">{status}</Badge>;
  }
  if (status === "INACTIVE") {
    return <Badge bg="danger">{status}</Badge>;
  }
  return <Badge bg="warning">{status}</Badge>;
};
