import { relativeTodayDate } from "../utils/helpers";
import { EditIcon, DeleteIcon } from "./Icons";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/esm/Button";
const TableNotes = ({ notes, showButtons = false }) => {
  if (!notes || notes.length === 0)
    return (
      <div className="container-row flex-center">
        <h3>Notes not found!!</h3>
      </div>
    );

  return (
    notes.length > 0 && (
      <Table striped bordered hover responsive="sm">
        <thead>
          <tr>
            <th>Time</th>
            <th>Rep</th>
            <th>Account</th>
            <th>Note</th>
            {showButtons && <th></th>}
          </tr>
        </thead>
        <tbody>
          {notes.map((note) => (
            <tr key={note._id}>
              <td>{relativeTodayDate(note.createdAt)}</td>
              <td>{note.rep}</td>
              <td>{note.accountName}</td>
              <td>{note.note}</td>
              {showButtons && (
                <td style={{ textAlign: "right" }}>
                  <Link to={`/notes/edit/${note._id}`}>
                    <Button variant="warning me-1">
                      <EditIcon />
                    </Button>
                  </Link>
                  <Link to={`/notes/delete/${note._id}`}>
                    <Button variant="danger">
                      <DeleteIcon />
                    </Button>
                  </Link>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  );
};

export default TableNotes;
