import { useParams } from "react-router-dom";

import { Loading } from "../components/Loading";
import { useAccount } from "../hooks/useAccounts";
import { useVisitsByAccountWithLimit } from "../hooks/useVisits";
import { useAccountContacts } from "../hooks/useAccountContacts";
import { useCallsByAccountWithLimit } from "../hooks/useCalls";
import { useNotesByAccountWithLimit } from "../hooks/useNotes";
import { useRoute } from "../hooks/useRoutes";
import { TableVisits } from "../components/TableVisits";
import TableCalls from "../components/TableCalls";
import TableNotes from "../components/TableNotes";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Card from "react-bootstrap/Card";

import CheckInCard from "../components/CheckInCard";

export const CheckInFromRoute = ({ user, location }) => {
  const { id, accountId } = useParams();
  const routeQuery = useRoute(id);
  const accountQuery = useAccount(accountId);
  const contactsQuery = useAccountContacts(accountId);
  const visitsQuery = useVisitsByAccountWithLimit(accountId, 5);
  const callsQuery = useCallsByAccountWithLimit(accountId, 5);
  const notesQuery = useNotesByAccountWithLimit(accountId, 5);

  if (
    accountQuery.isLoading ||
    contactsQuery.isLoading ||
    visitsQuery.isLoading ||
    callsQuery.isLoading ||
    notesQuery.isLoading ||
    routeQuery.isLoading
  ) {
    return <Loading message="Loading Account Information..." />;
  }

  const account = accountQuery.data;

  const contacts = contactsQuery.data;

  return (
    <Container className="mt-4">
      <CheckInCard
        user={user}
        account={account}
        contacts={contacts}
        location={location}
        routeId={id}
      />
      <Card className="mt-2">
        <Card.Body>
          {callsQuery.isSuccess && (
            <>
              <Row>
                <Col>
                  <h4>Last 5 Qc-Calls</h4>
                </Col>
              </Row>
              <TableCalls calls={callsQuery.data} />
            </>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          {notesQuery.isSuccess && (
            <>
              <Row>
                <Col>
                  <h4>Last 5 Notes</h4>
                </Col>
              </Row>
              <TableNotes notes={notesQuery.data} />
            </>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          {visitsQuery.isSuccess && (
            <>
              <Row>
                <Col>
                  <h4>Last 5 Visits</h4>
                </Col>
              </Row>
              <TableVisits visits={visitsQuery.data} />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};
