import { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useInitialsDeliveredFromTo } from "../../../hooks/useInitials.js";
import { Loading } from "../../../components/Loading";
import { TableInitialsDelivered } from "../../../components/TableInitialsDelivered.jsx";

const InitialDeliveriesReport = () => {
  //Today input format
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  const todayFormatted = yyyy + "-" + mm + "-" + dd;
  const [query, setQuery] = useState({
    from: todayFormatted,
    to: todayFormatted,
  });
  const [search, setSearch] = useState({
    from: "",
    to: "",
  });

  const initialsQuery = useInitialsDeliveredFromTo(search.from, search.to);

  const onHandleChange = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    setSearch({ ...search, from: query.from, to: query.to });
  };

  if (initialsQuery.isLoading) return <Loading message="Loading" />;

  console.log(initialsQuery.data);

  return (
    <Container className="my-4">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Initials Delivered Report</h3>
            <Link to="/admin/reports">Reports</Link>
          </div>
        </Card.Header>
        <Card.Body>
          <Row className="mt-2">
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label>From</Form.Label>
                <Form.Control
                  type="date"
                  size="lg"
                  name="from"
                  value={query.from}
                  onChange={onHandleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label>To</Form.Label>
                <Form.Control
                  type="date"
                  size="lg"
                  name="to"
                  value={query.to}
                  onChange={onHandleChange}
                />
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={2}
              className="d-flex flex-column justify-content-end"
            >
              <Button
                type="button"
                onClick={handleClick}
                variant="primary"
                size="lg"
              >
                View
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="mt-4 d-flex flex-column align-items-center justify-content-center">
              <h3>
                {query.from} - {query.to}
              </h3>
            </Col>
            {initialsQuery.isSuccess && (
              <TableInitialsDelivered initials={initialsQuery.data} />
            )}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default InitialDeliveriesReport;
