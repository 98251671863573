import { useAccounts, useSearchAccounts } from "../../../hooks/useAccounts";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { useState } from "react";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import { TableAccounts } from "../../../components/TableAccounts";

const HomeAccounts = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const accountsQuery = useSearchAccounts(searchTerm);

  return (
    <Container className="mt-4 margin-container-bottom " fluid>
      <Row>
        <Col>
          <Card>
            <Card.Header>
              <h3>Accounts</h3>
            </Card.Header>
            <Card.Body>
              <Row className="mb-2">
                <Col>
                  <InputGroup size="lg">
                    <InputGroup.Text id="inputGroup-sizing-lg">
                      Search account by name
                    </InputGroup.Text>
                    <Form.Control
                      aria-label="Large"
                      aria-describedby="inputGroup-sizing-sm"
                      onChange={(e) => setSearchTerm(e.target.value)}
                      value={searchTerm}
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {accountsQuery.isLoading && <p>Loading...</p>}
                  <TableAccounts
                    accounts={accountsQuery.data}
                    showAllInformation={true}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HomeAccounts;
