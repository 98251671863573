import { useQuery } from "react-query";
import axios from "axios";

//GET Route by Date
export function useRouteByDate(rep, date) {
  const query = useQuery(
    ["route", rep, date],
    async () => {
      const res = await axios.get(`/api/routes/date/${rep}/${date}`);
      return res.data;
    },
    {
      enabled: !!date && !!rep,
    }
  );
  return query;
}

//GET Route by Id
export function useRoute(id) {
  const query = useQuery(
    ["route", id],
    async () => {
      const res = await axios.get(`/api/routes/${id}`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
  return query;
}
