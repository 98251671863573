import { useNavigate } from "react-router-dom";
import { relativeTodayDate } from "../utils/helpers";
import Button from "react-bootstrap/esm/Button";
import { EditIcon, DeleteIcon } from "./Icons";
import Table from "react-bootstrap/Table";
import _ from "lodash";

const TableCalls = ({ calls, showButtons = false, rep = null }) => {
  const navigate = useNavigate();

  if (!calls || calls.length === 0) {
    return <div>No Qc-Calls for this account</div>;
  }

  //sort calls by date using lodash Oldest to newest
  const sortedCalls = _.orderBy(calls, ["localCreatedAt"], ["asc"]);

  return (
    <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Date</th>
          <th>Account</th>
          <th>Rep</th>
          <th>Note</th>
          {showButtons && rep && <th></th>}
        </tr>
      </thead>
      <tbody>
        {sortedCalls.map((call, index) => (
          <tr key={call._id}>
            <td>{index + 1}</td>
            <td>{relativeTodayDate(call.localCreatedAt)}</td>
            <td>{call.account.name}</td>
            <td>{call.rep}</td>
            <td style={{ width: "40%" }}>{call.note}</td>
            {showButtons && (
              <td style={{ width: "120px" }} className="text-end">
                {rep === call.rep && (
                  <>
                    <Button
                      variant="warning"
                      className="me-1"
                      onClick={() => navigate(`/qcCalls/edit/${call._id}`)}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => navigate(`/qcCalls/delete/${call._id}`)}
                    >
                      <DeleteIcon />
                    </Button>
                  </>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default TableCalls;
