import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useAccount } from "../../hooks/useAccounts";
import { useAccountContact } from "../../hooks/useAccountContacts";
import { Link } from "react-router-dom";
import axios from "axios";
import { BsTrash } from "react-icons/bs";

export const DeleteContact = ({ user }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const contactQuery = useAccountContact(id);

  const handleDeleteClick = async () => {
    const response = await axios.delete(
      `/api/account-contacts/${contact._id}/`
    );

    if (response.status === 200) navigate(`/account/${contact.AccountId._id}`);
  };

  if (contactQuery.isLoading) {
    return <Loading message="Loading  Information..." />;
  }

  const contact = contactQuery.data;

  return (
    <div className="container-base">
      <div className="container-row flex-end mt-10">
        <Link
          to={`/account/${contact.AccountId._id}`}
          className="button button-info"
        >
          Back
        </Link>
      </div>
      <div className="container-row">
        <div className="white-card text-card flex-column mt-10">
          <div className="container-row mt-20">
            <h1>
              Delete Contact {contact.name} from {contact.AccountId.name}
            </h1>
          </div>
          <div className="container-row mt-20">
            <div className="account-info-grid-container">
              <div className="account-info-grid-item-header">Name</div>
              <div className="account-info-grid-item">{contact.name}</div>
              <div className="account-info-grid-item-header">Position</div>
              <div className="account-info-grid-item">{contact.position}</div>
              <div className="account-info-grid-item-header">Birthday</div>
              <div className="account-info-grid-item">{contact.birthday}</div>
              <div className="account-info-grid-item-header">Phone</div>
              <div className="account-info-grid-item">{contact.phone}</div>
              <div className="account-info-grid-item-header">Email</div>
              <div className="account-info-grid-item">{contact.email}</div>
              <div className="account-info-grid-item-header">Notes</div>
              <div className="account-info-grid-item">{contact.notes}</div>
            </div>
          </div>
          <div className="container-row mt-10 mb-10">
            {user.isAdmin ? (
              <button
                type="button"
                className="button button-danger"
                onClick={handleDeleteClick}
              >
                <BsTrash /> Delete Contact
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
