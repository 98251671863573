import { Link } from "react-router-dom";
import { useCallsByDateRange } from "../../hooks/useCalls";
import { BackIcon, EditIcon, DeleteIcon } from "../../components/Icons";
import Container from "react-bootstrap//Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { todayInputFormat } from "../../utils/dates";
import Card from "react-bootstrap/Card";
import { Loading } from "../../components/Loading";

const IndexQcCalls = () => {
  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <Row>
            <Col className="d-flex justify-content-between">
              <h3>Qc-Calls</h3>
              <Link to={`/`}>
                <BackIcon /> Back
              </Link>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Link to="/qcCalls/Create" className="btn btn-primary">
                Create
              </Link>
              <Link to="/qcCalls/today" className="btn btn-primary">
                Today
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default IndexQcCalls;
