import { Link, useNavigate } from "react-router-dom";

import { MdSwitchAccount, MdAddIcCall } from "react-icons/md";
import ToolTip from "../Tooltip";
import { CheckInIcon, EditIcon, DeleteIcon } from "../Icons";
import { CHECK_IN_MIN_DISTANCE } from "../../utils/constantValues";

import Button from "react-bootstrap/Button";

export const TableActionButton = ({ account, showAllInformation = false }) => {
  const navigate = useNavigate();

  return (
    <div className="d-grid gap-2">
      {account.distance <= CHECK_IN_MIN_DISTANCE && (
        <Button
          variant="success"
          size="lg"
          onClick={() => navigate(`/checkIn/${account._id}`)}
        >
          <CheckInIcon /> Check In
        </Button>
      )}
      <Button
        variant="info"
        size="lg"
        onClick={() => navigate(`/qcCalls/create/${account._id}`)}
      >
        <MdAddIcCall /> Qc-Call
      </Button>

      <Button
        variant="primary"
        size="lg"
        onClick={() => navigate(`/account/${account._id}`)}
      >
        <MdSwitchAccount /> Account
      </Button>

      {showAllInformation && (
        <>
          <Button
            variant="warning"
            size="lg"
            onClick={() => navigate(`/admin/account/edit/${account._id}`)}
          >
            <EditIcon /> Edit
          </Button>

          <Button
            variant="danger"
            size="lg"
            onClick={() => navigate(`/account/delete/${account._id}`)}
          >
            <DeleteIcon /> Delete
          </Button>
        </>
      )}
    </div>
  );
};
