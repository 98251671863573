import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useLocationsByUser } from "../../hooks/useLocation";
import axios from "axios";
import GoogleAddressAutocomplete from "../../components/inputs/GoogleAddressAutocomplete";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/esm/Form";
import { Loading } from "../../components/Loading";
import _ from "lodash";
import CardLocations from "../../components/CardLocations";

const CreateLocation = ({ user }) => {
  const navigate = useNavigate();
  const [place, setPlace] = useState();
  const [address, setAddress] = useState({
    addressLine1: "",
    city: "",
    state: "",
    zip: "",
  });

  const { register, handleSubmit } = useForm();

  const locationsQuery = useLocationsByUser(user.localUsername);

  const onSubmit = async (data) => {
    try {
      if (place) {
        data.name = data.name.toUpperCase();
        data.username = user.localUsername;
        data.addressLine1 = place.address.addressLine1;
        data.city = place.address.city;
        data.state = place.address.state;
        data.zip = place.address.zip;
        data.lat = place.geometry.location.lat();
        data.lng = place.geometry.location.lng();
      }

      //call axios create location
      const res = await axios.post("/api/locations", data);
      if (res.status === 201) {
        locationsQuery.refetch();
      }
    } catch (err) {
      console.log(err);
      return alert("Error creating location");
    }
  };

  if (locationsQuery.isLoading) return <Loading message="Loading Locations" />;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <h3>Create Location</h3>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Location name"
                    {...register("name", { required: true })}
                    size="lg"
                  />
                </Form.Group>
                <div className="mt-2">
                  <GoogleAddressAutocomplete handleSetPlace={setPlace} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <h5>Address</h5>
                    {place && (
                      <>
                        <p>
                          {place.address.addressLine1}
                          <br></br>
                          {place.address.city}, {place.address.state}{" "}
                          {place.address.zip}
                        </p>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button type="submit" variant="primary" size="lg">
                  Create
                </Button>
              </Col>
            </Row>
          </Form>
          <Row className="mt-3">
            <Col>
              {locationsQuery.isSuccess && locationsQuery.data && (
                <CardLocations locationQuery={locationsQuery} />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateLocation;
