import { useQuery } from "react-query";
import axios from "axios";

//GET rep checkins by date
export function useCheckIns(rep, date) {
  const query = useQuery(
    ["checkins", rep, date],
    async () => {
      const res = await axios.get(`/api/visits/checkins/${rep}/${date}`);
      return res.data;
    },
    {
      enabled: !!rep && !!date,
    }
  );
  return query;
}

//GET rep checkins by date
export function useCheckInsByFromTo(rep, dateFrom, dateTo) {
  const query = useQuery(
    ["checkins", rep, dateFrom, dateTo],
    async () => {
      const res = await axios.get(
        `/api/visits/checkins/${rep}/from/${dateFrom}/to/${dateTo}`
      );
      return res.data;
    },
    {
      enabled: !!rep && !!dateFrom && !!dateTo,
    }
  );
  return query;
}

//GET rep checkins by week
export function useCheckInsByWeek(rep, date) {
  const query = useQuery(
    ["checkins", rep, date],
    async () => {
      const res = await axios.get(`/api/visits/checkins/week/${rep}/${date}`);
      return res.data;
    },
    {
      enabled: !!rep && !!date,
    }
  );
  return query;
}
