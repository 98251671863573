import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/esm/Container";

const CardReferralsData = ({ referrals, title }) => {
  if (!referrals) return null;

  const legalReferrals = referrals.filter(
    (referral) => referral.account.type === "LEGAL"
  );
  const medicalReferrals = referrals.filter(
    (referral) => referral.account.type === "MEDICAL"
  );
  const othersReferrals = referrals.filter(
    (referral) => referral.account.type === "OTHERS"
  );
  return (
    <Card className="mb-2">
      <Card.Header>{title}</Card.Header>
      <Card.Body>
        <Link to="/reports/legal-referrals/" className="link">
          <Container className="d-flex justify-content-md-between">
            <div className="card-result-title">Legal</div>
            <div className="card-result-text">{legalReferrals.length}</div>
          </Container>
        </Link>

        <Link to="/reports/medical-referrals/" className="link">
          <Container className="d-flex justify-content-md-between">
            <div className="card-result-title">Medical</div>
            <div className="card-result-text">{medicalReferrals.length}</div>
          </Container>
        </Link>
        <Link to="/reports/others-referrals/" className="link">
          <Container className="d-flex justify-content-md-between">
            <div className="card-result-title">Others</div>
            <div className="card-result-text">{othersReferrals.length}</div>
          </Container>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default CardReferralsData;
