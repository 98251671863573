import { ICONS } from "../utils/constantValues";
import { HomeLink } from "../components/HomeLink";
import { useReferralsByYear } from "../hooks/useReferrals";
import { Loading } from "../components/Loading";
import { useInitialsNotDelivered } from "../hooks//useInitials";
import _ from "lodash";
import CardReferralsData from "../components/CardReferralsData";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Dashboard = ({ user }) => {
  //Get year from today
  const year = new Date().getFullYear();
  //Get Month from today
  const month = new Date().getMonth() + 1;
  //GET  year referrals
  const referralsQuery = useReferralsByYear(year);

  const initialsQuery = useInitialsNotDelivered();

  if (referralsQuery.isLoading && initialsQuery.isLoading)
    return <Loading message="Loading..." />;

  const referrals = referralsQuery.data;
  //Month Referrals information
  let monthReferrals = [];

  if (referralsQuery.isSuccess && referrals) {
    monthReferrals = _.filter(referrals, { month: month });
  }

  //Get Initials
  const initials = initialsQuery.data;
  //filter initials by rep
  //const initialsByRep = _.filter(initials, { rep: user.localUsername });
  const totalInitials = initials ? initials.length : 0;
  console.log("user", user);
  return (
    <Container className="mt-4">
      {user.isAdmin && (
        <Row>
          <Col xs={12} md={6}>
            <CardReferralsData referrals={monthReferrals} title="Month" />
          </Col>
          <Col xs={12} md={6}>
            <CardReferralsData referrals={referrals} title="Year" />
          </Col>
        </Row>
      )}
      <Row className="">
        <Col className="d-flex flex-wrap justify-content-between">
          <HomeLink icon={ICONS.search} title="Search" to="/Search" />

          {user.isAdmin && (
            <>
              <HomeLink icon={ICONS.nearMeIcon} title="Near Me" to="/nearme" />
              <HomeLink
                icon={ICONS.byLocation}
                title="By Location"
                to="/location"
              />
              <HomeLink icon={ICONS.notes} title="Notes" to="/notes" />
              <HomeLink icon={ICONS.today} title="Today" to="/visits/today" />
              <HomeLink
                icon={ICONS.initials}
                title="Initials"
                to="/initials"
                initials={totalInitials}
              />
            </>
          )}
          <HomeLink
            icon={ICONS.prospect}
            title="Prospect"
            to="/createProspect"
          />
          <HomeLink icon={ICONS.active} title="Active" to="/active" />
          <HomeLink icon={ICONS.inactive} title="Inactive" to="/inactive" />
          <HomeLink icon={ICONS.prospects} title="Prospect" to="/prospects" />
          <HomeLink icon={ICONS.qcCalls} title="Qc-Calls" to="/qcCalls/today" />
          <HomeLink
            icon={ICONS.qcCalls}
            title="QcCalls Reports"
            to="/admin/reports/qcCalls"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
