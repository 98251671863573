import React from "react";
import { BsTrash, BsPlusCircle } from "react-icons/bs";
import { AiOutlineEdit } from "react-icons/ai";
import { IoChevronBackCircleOutline } from "react-icons/io5";
import { BsCalendarCheckFill } from "react-icons/bs";
import { FaRegCalendarCheck, FaSearch } from "react-icons/fa";

export const DeleteIcon = () => {
  return <BsTrash />;
};

export const EditIcon = () => {
  return <AiOutlineEdit />;
};

export const NewIcon = () => {
  return <BsPlusCircle />;
};

export const BackIcon = () => {
  return <IoChevronBackCircleOutline />;
};

export const CheckInIcon = () => {
  return <BsCalendarCheckFill />;
};

export const SearchIcon = () => {
  return <FaSearch />;
};
