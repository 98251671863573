import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCallById } from "../../hooks/useCalls";
import axios from "axios";

import Container from "react-bootstrap/esm/Container";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Loading } from "../../components/Loading";

const EditCall = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { register, handleSubmit } = useForm();

  const callQuery = useCallById(id);

  const onSubmit = async (data) => {
    try {
      //Add axios call to delete account
      const res = await axios.put(`/api/calls/${id}`, data);
      console.log(res);
      if (res.status === 200) {
        navigate("/qcCalls/today");
      }
    } catch (err) {
      console.log(err);
      return alert("Error Updating  the call");
    }
  };

  if (callQuery.isLoading) return <Loading message="Loading Information ..." />;

  const call = callQuery.data;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Body>
          <Row>
            <Col>
              <h2>Edit Call</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="mt-3">
                  <Col>
                    <FloatingLabel
                      controlId="floatingTextareaNote"
                      label="Note"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Type your note here"
                        style={{ height: "100px" }}
                        name="note"
                        defaultValue={call.note}
                        {...register("note", { required: true })}
                        size="lg"
                      />
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col className="d-flex justify-content-center">
                    <Button type="submit" variant="warning" size="lg">
                      Edit Call
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditCall;
