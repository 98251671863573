import { useState } from "react";
import { useRepActives } from "../../../hooks/useUser";
import { useDirections } from "../../../hooks/useDirections";
import { Link } from "react-router-dom";
import { Loading } from "../../../components/Loading";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { TableRoute } from "../../../components/TableRoute";
import _ from "lodash";

const RouteReport = () => {
  //Today input format
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();
  const todayFormatted = yyyy + "-" + mm + "-" + dd;
  const generateRoute = "n";

  const [query, setQuery] = useState({
    rep: "",
    date: todayFormatted,
  });
  const repsQuery = useRepActives();
  const directionsQuery = useDirections(query.rep, query.date, generateRoute);

  const onHandleChange = (e) => {
    setQuery({ ...query, [e.target.name]: e.target.value });
  };

  const handleClick = (e) => {
    e.preventDefault();
    console.log(query);
  };

  if (repsQuery.isLoading && directionsQuery.isLoading) {
    return <Loading message="Loading..." />;
  }

  const reps = repsQuery.data;

  if (directionsQuery.isSuccess) {
    directionsQuery.data = _.sortBy(directionsQuery.data, ["status"]);
    console.log(directionsQuery.data);
  }

  return (
    <Container className="mt-4" style={{ marginBottom: "200px" }}>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <h3>Route Report</h3>
            <Link to="/admin/">Admin</Link>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label>Rep</Form.Label>
                <Form.Select
                  aria-label="select account status"
                  size="lg"
                  name="rep"
                  onChange={onHandleChange}
                  value={query.rep}
                >
                  <option value="">Select Rep</option>
                  {repsQuery.isSuccess &&
                    reps.map((rep) => (
                      <option key={rep._id} value={rep.localUsername}>
                        {rep.fullName}
                      </option>
                    ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} md={5}>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  size="lg"
                  name="date"
                  value={query.date}
                  onChange={onHandleChange}
                  max={todayFormatted}
                />
              </Form.Group>
            </Col>
            <Col
              xs={12}
              md={2}
              className="d-flex flex-column justify-content-end"
            >
              <Button
                type="button"
                onClick={handleClick}
                variant="primary"
                size="lg"
              >
                View
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              {directionsQuery.isSuccess && (
                <TableRoute routes={directionsQuery.data} title="title" />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default RouteReport;
