import { AccountName } from "./Table/AccountName";
import { AccountType } from "./Table/AccountType";
import { TableActionButton } from "./Table/TableActionButton";
import { AccountStatus } from "./Table/AccountStatus";
import { AccountAddress } from "./Table/AccountAddress";
import Table from "react-bootstrap/Table";

export const TableAccounts = ({
  accounts,
  showDistance = false,
  showAllInformation = false,
}) => {
  //Validate if accounts is null
  if (!accounts) return null;

  return (
    <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th>Rep</th>
          <th>Name</th>
          <th className="text-center">Type</th>
          <th>Address</th>
          {showDistance && <th className="text-center">Distance</th>}
          {showAllInformation && (
            <>
              <th className="text-center">Zone</th>
            </>
          )}

          <th className="text-center">Last Visit</th>
          <th className="text-center">Last Referral</th>

          <th></th>
        </tr>
      </thead>
      <tbody>
        {accounts
          ? accounts.map((account, index) => (
              <tr
                key={account._id}
                className={
                  account.lastVisitDaysAgoInteger >= account.frequency
                    ? "table-danger"
                    : null
                }
              >
                <td className="text-center">{index + 1}</td>
                <td>{account.rep}</td>
                <td style={{ width: "25%" }}>
                  <AccountName account={account} />
                </td>
                <td className="text-center">
                  <AccountType accountType={account.type} />
                  <br></br>
                  <AccountStatus status={account.statusV2} />
                </td>
                <td>
                  <AccountAddress account={account} />
                </td>
                {showDistance && (
                  <td className="text-center">{account.distance}</td>
                )}
                {showAllInformation && (
                  <>
                    <th className="text-center">
                      {account.zone} ({account.zq})
                    </th>
                  </>
                )}
                <td className="text-center">{account.lastVisitDaysAgo}</td>
                <td className="text-center">{account.lastReferralDaysAgo}</td>
                <td style={{ width: "160px", textAlign: "right" }}>
                  <TableActionButton
                    account={account}
                    showAllInformation={showAllInformation}
                  />
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </Table>
  );
};
