import { useState } from "react";
import { Loading } from "../components/Loading";
import { TableRoute } from "../components/TableRoute";
import { getMonday, getDateInputFormat } from "../utils/helpers";
import { useDirections } from "../hooks/useDirections";
import { useLocationsByUser } from "../hooks/useLocation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { format } from "date-fns";

import addDays from "date-fns/addDays";
import { Link } from "react-router-dom";

const MyRoute = ({ user, location }) => {
  const today = new Date();
  const [date, setDate] = useState(getDateInputFormat(getMonday(today)));
  const [zoneTitle, setZoneTitle] = useState({
    day: "MONDAY",
    destination: "",
  });
  const [destination, setDestination] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const generateRoute = "y";
  const rep = user.localUsername;
  const locationsQuery = useLocationsByUser(rep);

  //Monday of the current week
  const thisMonday = getMonday(today);
  const mondayInputFormat = getDateInputFormat(thisMonday);

  //Tuesday of the current week
  const thisTuesday = addDays(thisMonday, 1);
  const tuesdayInputFormat = getDateInputFormat(thisTuesday);

  //Wednesday of the current week
  const thisWednesday = addDays(thisMonday, 2);
  const wednesdayInputFormat = getDateInputFormat(thisWednesday);

  //Thursday of the current week
  const thisThursday = addDays(thisMonday, 3);
  const thursdayInputFormat = getDateInputFormat(thisThursday);

  //Friday of the current week
  const thisFriday = addDays(thisMonday, 4);
  const fridayInputFormat = getDateInputFormat(thisFriday);

  //Saturday of the current week
  const thisSaturday = addDays(thisMonday, 5);
  const saturdayInputFormat = getDateInputFormat(thisSaturday);

  //Sort Accounts by Google Route
  const directionsQuery = useDirections(
    rep,
    date,
    generateRoute,
    location.latitude,
    location.longitude,
    destination.lat,
    destination.lng
  );
  if (directionsQuery.isLoading || useLocationsByUser.isLoading)
    return (
      <Loading
        message={`Loading route for ${zoneTitle.day} ending destinatio at ${zoneTitle.destination}`}
      />
    );

  const locations = locationsQuery.data;

  const directions = directionsQuery.data;

  return (
    <Container fluid className="mt-4 margin-container-bottom">
      <Row className="mb-2">
        <Col className="d-flex justify-content-end">
          <Link to="/checkRoute"> Check Route</Link>
        </Col>
      </Row>
      <Card>
        <Card.Header>
          <div className="containter-route-header">
            <h1>Route Date </h1>
            <div className="container-route-buttons">
              <Button
                className="button button-info button-large button-zone me-1 mb-1"
                onClick={() => {
                  setDate(mondayInputFormat);
                  setZoneTitle({ ...zoneTitle, day: "MONDAY" });
                }}
              >
                MONDAY
              </Button>
              <Button
                className="button button-info button-large button-zone me-1 mb-1"
                onClick={() => {
                  setDate(tuesdayInputFormat);
                  setZoneTitle({ ...zoneTitle, day: "TUESDAY" });
                }}
              >
                TUESDAY
              </Button>
              <Button
                className="button button-info button-large button-zone me-1 mb-1"
                onClick={() => {
                  setDate(wednesdayInputFormat);
                  setZoneTitle({ ...zoneTitle, day: "WEDNESDAY" });
                }}
              >
                WEDNESDAY
              </Button>
              <Button
                className="button button-info button-large button-zone me-1 mb-1"
                onClick={() => {
                  setDate(thursdayInputFormat);
                  setZoneTitle({ ...zoneTitle, day: "THURSDAY" });
                }}
              >
                THURSDAY
              </Button>
              <Button
                className="button button-info button-large button-zone me-1 mb-1"
                onClick={() => {
                  setDate(fridayInputFormat);
                  setZoneTitle({ ...zoneTitle, day: "FRIDAY" });
                }}
              >
                FRIDAY
              </Button>
              <Button
                className="button button-info button-large button-zone mb-1"
                onClick={() => {
                  setDate(saturdayInputFormat);
                  setZoneTitle({ ...zoneTitle, day: "SATURDAY" });
                }}
              >
                SATURDAY
              </Button>
            </div>

            <h1>Destination</h1>

            <div className="mt-2">
              {locationsQuery.isSuccess &&
                locations &&
                locations.map((location) => (
                  <Button
                    variant="primary"
                    className="me-2"
                    size="lg"
                    key={location._id}
                    onClick={() => {
                      const data = {
                        name: location.name,
                        lat: location.lat,
                        lng: location.lng,
                      };
                      setDestination(data);
                      setZoneTitle({
                        ...zoneTitle,
                        destination: location.name,
                      });
                    }}
                  >
                    {location.name}
                  </Button>
                ))}
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {!directions && (
                <div className="d-flex justify-content-center">
                  <h4 className="text-danger">
                    Please create your route for {zoneTitle.day}
                  </h4>
                </div>
              )}
              {directionsQuery.isSuccess && (
                <TableRoute
                  routes={directionsQuery.data.route}
                  title={zoneTitle}
                  location={location}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MyRoute;
