import { useNavigate } from "react-router-dom";
import { useVisitsByDateRep } from "../../hooks/useVisits";
import { Loading } from "../../components/Loading";
import { TableVisits } from "../../components/TableVisits";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const TodayVisits = ({ user }) => {
  const navigate = useNavigate();
  const today = new Date();
  const todayString = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;

  const rep = user.localUsername;

  const visitsQuery = useVisitsByDateRep(rep, todayString, todayString);

  if (visitsQuery.isLoading) return <Loading message="Loading Visits..." />;

  const visits = visitsQuery.data;

  return (
    <Container fluid className="mt-4" style={{ marginBottom: "200px" }}>
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h2>Today's Visits</h2>
            <Button
              variant="primary"
              onClick={() => navigate("/visits/report")}
            >
              My Visit's Report
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <TableVisits visits={visits} edit={true} />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TodayVisits;
