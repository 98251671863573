import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { BackIcon, NewIcon } from "../../components/Icons";
import { COLORS } from "../../utils/colors";
import axios from "axios";
import Container from "react-bootstrap//Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const AddNoteAccount = ({ location }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [note, setNote] = useState({
    note: "",
    lat: location.latitude,
    lng: location.longitude,
    account: id,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!note.note) return alert("Note is required");

    const response = await axios.post("/api/notes", note);
    console.log(response);
    if (response.status === 201) {
      navigate(`/account/${id}`);
    }
  };

  const handleInputChange = (event) => {
    setNote({
      ...note,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h1>Create Note</h1>
            <div className="container-row flex-end mt-10">
              <Link to={`/account/${id}`} className="button button-info">
                <BackIcon /> Back
              </Link>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit}>
                <FloatingLabel controlId="floatingTextareaNote" label="Note">
                  <Form.Control
                    as="textarea"
                    placeholder="Type your note here"
                    style={{ height: "100px" }}
                    name="note"
                    value={note.note}
                    onChange={handleInputChange}
                  />
                </FloatingLabel>

                <div className="d-flex justify-content-center mt-4">
                  <Button type="submit" variant="primary" size="lg">
                    <NewIcon color={COLORS.white} /> Create Note
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddNoteAccount;
