import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { useAccount } from "../../../hooks/useAccounts";
import { Loading } from "../../../components/Loading";
import axios from "axios";
import GoogleAddressAutocomplete from "../../../components/inputs/GoogleAddressAutocomplete";

const EditAccountAddress = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [place, setPlace] = useState({});
  const accountQuery = useAccount(id);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    try {
      if (place) {
        data._id = id;
        data.addressLine1 = place.address.addressLine1;
        data.city = place.address.city;
        data.state = place.address.state;
        data.zip = place.address.zip;
        data.lat = place.address.lat;
        data.lng = place.address.lng;
        data.coordinates = [place.address.lng, place.address.lat];
        data.locationDb = {
          type: "Point",
          coordinates: [place.address.lng, place.address.lat],
        };
        console.log(data);
        //Add axios call to update account
        const res = await axios.put(`/api/accounts/${id}`, data);
        console.log(res);
        if (res.status === 200) {
          navigate(`/account/${id}`);
        }
      }
    } catch (err) {
      console.log(err);
      return alert("Error updating account");
    }
  };

  if (accountQuery.isLoading) {
    return <Loading message="Loading..." />;
  }

  const account = accountQuery.data;

  return (
    <Container className="mt-4 margin-container-bottom ">
      <Card>
        <Card.Header>
          <h3>Edit Account Address {account.name}</h3>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-2">
              <Col md={8} sm={12}>
                <GoogleAddressAutocomplete handleSetPlace={setPlace} />
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Address Line 2:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Suite, Apt, etc."
                    {...register("addressLine2")}
                    size="lg"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-center">
                <Button type="submit" variant="primary" size="lg">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditAccountAddress;
