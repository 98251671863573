import { useState } from "react";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axios from "axios";

const CardsPromo = ({ promosQuery }) => {
  const [promoToDelete, setPromoToDelete] = useState({
    _id: "",
    name: "",
    show: false,
  });

  const handleClose = () => {
    setPromoToDelete({ show: false });
  };
  const handleShow = (promo) => {
    setPromoToDelete({
      _id: promo._id,
      show: true,
      name: promo.name,
    });
  };

  const handleDeletePromo = async () => {
    try {
      const res = await axios.delete(`/api/promos/${promoToDelete._id}`);
      if (res.status === 200) {
        handleClose();
        promosQuery.refetch();
      }
    } catch (err) {
      console.log(err);
      return alert("Error deleting location");
    }
  };

  if (!promosQuery.data) return null;

  return (
    <>
      {promosQuery.data.map((promo) => (
        <Card key={promo._id} className="mb-2">
          <Card.Header>
            <h3>{promo.name}</h3>
            <i>{promo.localDate}</i>
            <p>{promo.budget ? `budget $ ${promo.budget.toFixed(2)}` : null}</p>
          </Card.Header>
          <Card.Footer>
            <Button variant="danger" onClick={() => handleShow(promo)}>
              Delete
            </Button>
          </Card.Footer>
        </Card>
      ))}

      <Modal show={promoToDelete.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Promo {promoToDelete.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-danger">Delete Promo</h4>
          <p>
            <strong>{promoToDelete.name}</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeletePromo}>
            Delete Promo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CardsPromo;
