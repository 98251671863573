import { useQuery } from "react-query";
import axios from "axios";

//GET Directions
export function useDirections(
  rep,
  date,
  generateRoute,
  latitude,
  longitude,
  endLatitud,
  endLongitude
) {
  const query = useQuery(
    ["directions", rep, date, latitude, longitude, endLatitud, endLongitude],
    async () => {
      const res = await axios.get(
        `/api/directions/${rep}/${date}/${generateRoute}/${latitude}/${longitude}/${endLatitud}/${endLongitude}`
      );
      return res.data;
    },
    {
      enabled: !!rep && !!date && !!endLatitud && !!endLongitude,
    }
  );
  return query;
}
