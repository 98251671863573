import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import { useEffect, useRef } from "react";
import axios from "axios";

export const FormSwitch = ({ call, user = null, fieldToUpdate }) => {
  const { register, handleSubmit, watch, control } = useForm();
  const fieldName = `${fieldToUpdate}_${call._id}`;
  const watchedField = watch(fieldName);
  const isFirstRender = useRef(true); // Reference to track the first render
  const title = fieldToUpdate === "reportToMarketingRep" ? "Report" : "Review";

  const submitForm = async (data) => {
    try {
      console.log("Submitted", data);

      if (!data._id) return;
      //Add axios call to update call
      const res = await axios.put(`/api/calls/${data._id}`, data);
      console.log("Call Updated", res);
    } catch (err) {
      console.log(err);
      //return alert(err);
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render
      isFirstRender.current = false;
      return;
    }

    submitForm({
      [`${fieldToUpdate}`]: watchedField,
      _id: call._id,
    });
  }, [watchedField, call._id, fieldToUpdate]);

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Controller
        name={fieldName}
        control={control} // control prop from useForm
        defaultValue={call[fieldToUpdate] || false}
        render={({ field }) => (
          <Form.Check
            {...field}
            type="switch"
            id={`custom-switch-${call._id}`}
            label={`${title}`}
            checked={field.value} // using field.value here ensures proper behavior
          />
        )}
      />
      <Form.Control
        type="hidden"
        name="_id"
        defaultValue={call._id}
        {...register(`_id`)}
      />
      {console.log("FormSwitch", call)}
    </form>
  );
};

export default FormSwitch;
