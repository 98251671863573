import React from "react";
import useFetchData from "../../hooks/useFetchData";
import TableCallsWithUpdateSwitchField from "../../components/TableCallsWithUpdateSwitchField";
function PotentialsQcCalls() {
  const { data, error } = useFetchData("/api/calls/potentials");

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (data) {
    return (
      <div>
        <TableCallsWithUpdateSwitchField
          calls={data}
          fieldToUpdate="isReviewed"
        />
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

export default PotentialsQcCalls;
