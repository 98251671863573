import { useQuery } from "react-query";
import axios from "axios";

//GET Referrlas by account
export function useReferralsByAccount(accountId) {
  const referralsQuery = useQuery(
    ["referrals", "account", accountId],
    async () => {
      const res = await axios.get(`/api/referrals/account/${accountId}`);
      return res.data;
    },
    {
      enabled: !!accountId,
    }
  );
  return referralsQuery;
}

//GET Referrals by Year
export function useReferralsByYear(year) {
  const referralsQuery = useQuery(
    ["referrals", "year", year],
    async () => {
      const res = await axios.get(`/api/referrals/year/${year}`);
      return res.data;
    },
    {
      enabled: !!year,
    }
  );
  return referralsQuery;
}
