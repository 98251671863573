import { calculateDistance } from "../utils/helpers";
import { CHECK_IN_MIN_DISTANCE, STATUS } from "../utils/constantValues";
import { AccountType } from "./Table/AccountType";
import { Link } from "react-router-dom";
import { AccountAddress } from "./Table/AccountAddress";
import Table from "react-bootstrap/esm/Table";
import _ from "lodash";

export const TableRoute = ({ routes, title, location = null }) => {
  //Filter routers without account
  // let routesFiltered = routes.filter((route) => route.account !== null);

  if (!routes) return null;

  // if (location && routes) {
  //   routesFiltered = routesFiltered.map((route) => {
  //     const distance = calculateDistance(
  //       location.latitude,
  //       location.longitude,
  //       route.account.lat,
  //       route.account.lng
  //     );
  //     return { ...route, distance };
  //   });
  // }

  return (
    <Table striped bordered hover responsive="sm">
      <thead>
        <tr>
          <th
            colSpan={7}
            style={{ textAlign: "center", fontSize: 24, fontWeight: "800" }}
          >
            {`${title.day} ROUTE ENDING AT ${title.destination}`}
          </th>
        </tr>
        <tr>
          <th>#</th>
          <th>Account</th>
          <th>Type</th>
          <th>Start Address</th>
          <th>End Address</th>
          <th>Duration / Distance </th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {routes.map((route, index) => (
          <tr
            key={index}
            className={
              route.status === STATUS.Pending ? "table-danger" : "table-success"
            }
          >
            <td>{index + 1}</td>
            <td>{route.account.name}</td>
            <td>
              <AccountType accountType={route.account.type} />
            </td>
            <td>{route.leg.start_address}</td>
            <td>
              <AccountAddress
                account={route.account}
                distance={route.distance ? route.distance : null}
              />
            </td>
            <td>
              {route.leg &&
                `${route.leg.duration.text} / ${route.leg.distance.text}`}
            </td>
            <td>{route.status}</td>
            <td className="text-center" style={{ width: "120px" }}>
              {route.status === STATUS.Pending && (
                <Link
                  className="btn btn-warning"
                  to={`/checkInFromRoute/${route._id}/${route.account._id}`}
                >
                  Check-In
                </Link>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};
