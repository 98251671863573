import { useState } from "react";
import axios from "axios";

import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

const CardLocations = ({ locationQuery }) => {
  const [locationToDelete, setLocationToDelete] = useState({
    _id: "",
    name: "",
    addressLine1: "",
    addressLine2: "",
    show: false,
  });

  const handleClose = () => {
    setLocationToDelete({ show: false });
  };
  const handleShow = (location) => {
    setLocationToDelete({
      _id: location._id,
      show: true,
      name: location.name,
      addressLine1: `${location.addressLine1}`,
      addressLine2: `${location.city}, ${location.state} ${location.zip}`,
    });
  };

  const handleDeleteLocation = async () => {
    try {
      const res = await axios.delete(`/api/locations/${locationToDelete._id}`);
      if (res.status === 200) {
        handleClose();
        locationQuery.refetch();
      }
    } catch (err) {
      console.log(err);
      return alert("Error deleting location");
    }
  };

  if (!locationQuery.data) return null;

  return (
    <>
      {locationQuery.data.map((location) => (
        <Card key={location._id} className="mb-2">
          <Card.Header>
            <h3>{location.name}</h3>
          </Card.Header>
          <Card.Body>
            <p>
              {location.addressLine1}
              <br></br>
              {location.city}, {location.state} {location.zip}
            </p>
          </Card.Body>
          <Card.Footer>
            <Button
              variant="danger"
              size="lg"
              onClick={() => handleShow(location)}
            >
              Delete
            </Button>
          </Card.Footer>
        </Card>
      ))}

      <Modal show={locationToDelete.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Location {locationToDelete.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-danger">Delete Location</h4>
          <p>Are you sure you want to delete this location?</p>

          <p>
            <strong>{locationToDelete.name}</strong>
            <br></br>
            {locationToDelete.addressLine1}
            <br></br>
            {locationToDelete.addressLine2}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleDeleteLocation}>
            Delete Location
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CardLocations;
