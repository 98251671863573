import { Link } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { useNotesByDate } from "../../hooks/useNotes";
import { BackIcon } from "../../components/Icons";
import TableNotes from "../../components/TableNotes";
import { useState } from "react";
import { shortDate } from "../../utils/helpers";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

const NoteByDate = ({ user }) => {
  const [date, setDate] = useState("");

  const notesQuery = useNotesByDate(date, user.localUsername);

  if (notesQuery.isLoading) return <Loading message="Loading ...." />;

  const notes = notesQuery.data;

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header>
          <Row>
            <Col className="d-flex justify-content-between">
              <h1>Notes by Date</h1>
              <div>
                <Link to="/notes/create" className="button button-submit mr-5">
                  Create Note
                </Link>{" "}
                |{" "}
                <Link to={`/notes`} className="button button-info">
                  <BackIcon /> Back
                </Link>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  onChange={(e) => setDate(e.target.value)}
                  size="lg"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1>Notes {date && shortDate(date)}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <TableNotes notes={notes} showButtons={false} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default NoteByDate;
