import { useForm } from "react-hook-form";

import { useParams, useNavigate } from "react-router-dom";
import { useVisitById } from "../../hooks/useVisits";
import { useAccountContacts } from "../../hooks/useAccountContacts";
import { useVisitsByAccountWithLimit } from "../../hooks/useVisits";
import { useCallsByAccountWithLimit } from "../../hooks/useCalls";
import { useNotesByAccountWithLimit } from "../../hooks/useNotes";
import { usePromoByMonthYearAndRep } from "../../hooks/usePromo";
import { TableVisits } from "../../components/TableVisits";
import TableCalls from "../../components/TableCalls";
import TableNotes from "../../components/TableNotes";
import { Loading } from "../../components/Loading";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import { EditIcon, BackIcon } from "../../components/Icons";
import { TOUCHES } from "../../utils/constantValues";

const EditVisit = ({ user, location }) => {
  const navigate = useNavigate();
  const { id, accountId } = useParams();
  const visitQuery = useVisitById(id);
  const contactsQuery = useAccountContacts(accountId);

  const visitsQuery = useVisitsByAccountWithLimit(accountId, 5);
  const callsQuery = useCallsByAccountWithLimit(accountId, 5);
  const notesQuery = useNotesByAccountWithLimit(accountId, 5);

  //GET MONTH AND YEAR FROM TODAY
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const promoQuery = usePromoByMonthYearAndRep(month, year, user.localUsername);

  const {
    register,
    handleSubmit,
    setFocus,
    formState: { errors },
  } = useForm();

  if (
    visitQuery.isLoading ||
    contactsQuery.isLoading ||
    visitsQuery.isLoading ||
    callsQuery.isLoading ||
    notesQuery.isLoading ||
    promoQuery.isLoading
  )
    return <Loading message="Loading Information..." />;

  const visit = visitQuery.data;
  const contacts = contactsQuery.data;

  async function onSubmit(data) {
    //Validate empty notes
    if (data.notes === "") {
      setFocus("notes");
      return alert("Please add a valid note");
    }

    //Call api using axios
    const response = await axios.put(`/api/visits/${visit._id}`, data);
    if (response.status === 200) {
      //Redirect to Todays Visits
      navigate(`/visits/today/`);
    }
  }

  return (
    <Container className="mt-3">
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            <h2>Edit Visit {visit.account.name}</h2>
          </div>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Contact</Form.Label>
                <Form.Select
                  size="lg"
                  defaultValue={visit.contact}
                  {...register("contact", { required: true })}
                >
                  <option value="General Visit">General Visit</option>
                  {contacts.map((contact) => (
                    <option key={contact._id} value={contact.name}>
                      {contact.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mt-3">
                  <Form.Select
                    defaultValue={visit.mainTouch}
                    {...register("mainTouch", { required: true })}
                  >
                    <option value="">--Select a Touch--</option>
                    {TOUCHES.map((touch) => (
                      <option key={touch} value={touch}>
                        {touch}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Select
                    name="touch"
                    defaultValue={visit.touch}
                    {...register("touch", { required: true })}
                  >
                    <option value="HOT">🔥 HOT</option>
                    <option value="WARM">🌤 WARM</option>
                    <option value="COLD">🥶 COLD</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Select
                    name="promo"
                    defaultValue={visit.promo}
                    {...register("promo")}
                    size="lg"
                  >
                    <option value="No Promo">No Promo</option>
                    {promoQuery.data.map((promo) => (
                      <option key={promo._id} value={promo.name}>
                        {promo.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <FloatingLabel
                  controlId="floatingTextarea2"
                  label="Note"
                  className="mt-3"
                >
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a note here"
                    defaultValue={visit.notes}
                    {...register("notes")}
                    style={{ height: "100px" }}
                  />
                </FloatingLabel>
                <Form.Check
                  className="mt-2"
                  type="checkbox"
                  {...register("notifyOffice")}
                  label="Notify to office"
                />

                <div className="d-flex justify-content-center mt-2">
                  <Button
                    className="ms-2 me-2"
                    variant="primary"
                    size="lg"
                    onClick={() => navigate("/visits/today")}
                  >
                    <BackIcon /> Back
                  </Button>
                  <Button type="submit" variant="warning" size="lg">
                    <EditIcon /> Edit Visit
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          {callsQuery.isSuccess && (
            <>
              <Row>
                <Col>
                  <h4>Last 5 Qc-Calls</h4>
                </Col>
              </Row>
              <TableCalls calls={callsQuery.data} />
            </>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          {notesQuery.isSuccess && (
            <>
              <Row>
                <Col>
                  <h4>Last 5 Notes</h4>
                </Col>
              </Row>
              <TableNotes notes={notesQuery.data} />
            </>
          )}
        </Card.Body>
      </Card>
      <Card className="mt-2">
        <Card.Body>
          {visitsQuery.isSuccess && (
            <>
              <Row>
                <Col>
                  <h4>Last 5 Visits</h4>
                </Col>
              </Row>
              <TableVisits visits={visitsQuery.data} />
            </>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditVisit;
