import { useNotesByDate } from "../../hooks/useNotes";
import { Loading } from "../../components/Loading";
import { Link } from "react-router-dom";
import TableNotes from "../../components/TableNotes";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

export const IndexNotes = ({ user }) => {
  const today = new Date();
  const todayQuery = `${today.getFullYear()}-${
    today.getMonth() + 1
  }-${today.getDate()}`;
  const todayNotesQuery = useNotesByDate(todayQuery, user.localUsername);

  if (todayNotesQuery.isLoading) return <Loading message="Loading Notes..." />;

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <h3>Today's Notes</h3>
              <div>
                <Link to="/notes/create" className="me-2">
                  Create Note
                </Link>
                |
                <Link to="/notes/bydate" className="ms-2">
                  Notes by Date
                </Link>
              </div>
            </Card.Header>
            <Card.Body>
              <TableNotes notes={todayNotesQuery.data} showButtons={true} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
