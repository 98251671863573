import { useQuery } from "react-query";
import axios from "axios";

//GET Contacts By Account id
export function useAccountContacts(id) {
  const contactsQuery = useQuery(
    ["contacts", id],
    async () => {
      const res = await axios.get(`/api/account-contacts/account/${id}/`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
  return contactsQuery;
}

//GET Contact by id
export function useAccountContact(id) {
  const contactQuery = useQuery(
    ["contact", id],
    async () => {
      const res = await axios.get(`/api/account-contacts/${id}/`);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
  return contactQuery;
}
