import { useQuery } from "react-query";

//GET Accounts
export function useAccounts() {
  const accountsQuery = useQuery("accounts", async () => {
    const res = await fetch("/api/accounts");
    return res.json();
  });
  return accountsQuery;
}

export function useAccount(id) {
  const accountQuery = useQuery(
    ["account", id],
    async () => {
      const res = await fetch(`/api/accounts/${id}`);
      return res.json();
    },
    {
      enabled: !!id,
    }
  );
  return accountQuery;
}

//Get Accounts by Location
export function useAccountsByLocation(location) {
  const accountsQuery = useQuery(
    ["accounts", location],
    async () => {
      const res = await fetch(`/api/accounts/location/${location}`);
      return res.json();
    },
    {
      enabled: !!location,
    }
  );
  return accountsQuery;
}

//Get Active Accounts by Rep
export function useAccountsRep(rep, accountType) {
  const accountsQuery = useQuery(
    ["accounts", accountType, rep],
    async () => {
      const res = await fetch(`/api/accounts/rep/${rep}/${accountType}`);
      return res.json();
    },
    {
      enabled: !!rep && !!accountType,
    }
  );
  return accountsQuery;
}

//Get All Active Accounts
export function useActiveAccounts() {
  const accountsQuery = useQuery(["ActiveAccounts"], async () => {
    const res = await fetch(`/api/accounts/active`);
    return res.json();
  });
  return accountsQuery;
}

//GEt all Inactive accounts
export function useInactiveAccounts() {
  const accountsQuery = useQuery(["InactiveAccounts"], async () => {
    const res = await fetch(`/api/accounts/inactive`);
    return res.json();
  });
  return accountsQuery;
}

//Get All prospects Accounts
export function useProspectAccounts() {
  const accountsQuery = useQuery(["ProspectsAccounts"], async () => {
    const res = await fetch(`/api/accounts/prospects`);
    return res.json();
  });
  return accountsQuery;
}

//GET  Accounts by rep and qr
export function useAccountsRepAndQr(rep, qr) {
  const accountsQuery = useQuery(
    ["accounts", "Quadrant", rep, qr],
    async () => {
      const res = await fetch(`/api/accounts/rep-qr/${rep}/${qr}`);
      return res.json();
    },
    {
      enabled: !!rep && !!qr,
    }
  );
  return accountsQuery;
}

//GET Search Accounts
export function useSearchAccounts(search) {
  const accountsQuery = useQuery(
    ["accounts", search],
    async () => {
      const res = await fetch(`/api/accounts/search/${search}`);
      return res.json();
    },
    {
      enabled: !!search,
    }
  );
  return accountsQuery;
}
