import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { CHECK_IN_MIN_DISTANCE, TOUCHES } from "../utils/constantValues";
import { CheckInIcon } from "../components/Icons";
import { usePromoByMonthYearAndRep } from "../hooks/usePromo";

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import { calculateDistance } from "../utils/helpers";

import axios from "axios";
import { Loading } from "./Loading";

const CheckInCard = ({ user, account, contacts, location, routeId = null }) => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    contact: "General Visit",
    touch: "",
    promo: "No Promo",
  });
  const distance = calculateDistance(
    location.latitude,
    location.longitude,
    account.lat,
    account.lng
  );

  //GET MONTH AND YEAR FROM TODAY
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const promoQuery = usePromoByMonthYearAndRep(month, year, user.localUsername);

  function handleOnChange(event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  }

  //Handle Refresh Location
  const handleRefresh = () => {
    window.location.reload();
  };

  //Handle Check In
  const handleCheckIn = async () => {
    if (!values.touch) {
      return alert("Touch is required");
    }

    const data = {
      account: account._id,
      mainTouch: values.touch,
      checkInLat: location.latitude,
      checkInLng: location.longitude,
      contact: values.contact,
      promo: values.promo,
      touch: "",
    };

    //Validate if routeId is not null
    if (routeId) {
      data.routeId = routeId;
    }

    //Call api using axios
    const response = await axios.post("/api/visits/checkin", data);
    if (response.status === 200) {
      //Redirect to account page
      navigate(`/visits/edit/${response.data._id}/${data.account}`);
    }
  };

  if (promoQuery.isLoading) return <Loading message="Loading.." />;

  console.log(promoQuery);

  return (
    <Card>
      <Card.Header>
        <h3>Check In {account.name}</h3>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            {user.isAdmin ? (
              <>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Contact at Office</Form.Label>
                      <Form.Select
                        name="contact"
                        onChange={handleOnChange}
                        size="lg"
                      >
                        <option value="General Visit">General Visit</option>
                        {contacts.map((contact) => (
                          <option key={contact._id} value={contact.name}>
                            {contact.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Touch</Form.Label>
                      <Form.Select
                        name="touch"
                        onChange={handleOnChange}
                        size="lg"
                      >
                        <option value="">--Select a Touch--</option>
                        {TOUCHES.map((touch) => (
                          <option key={touch} value={touch}>
                            {touch}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>Promo</Form.Label>
                      <Form.Select
                        name="promo"
                        onChange={handleOnChange}
                        size="lg"
                      >
                        <option value="No Promo">No Promo</option>
                        {promoQuery.data.map((promo) => (
                          <option key={promo._id} value={promo.name}>
                            {promo.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="d-flex justify-content-center">
                    <Button variant="success" onClick={handleCheckIn} size="lg">
                      <CheckInIcon /> Check In
                    </Button>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {distance >= CHECK_IN_MIN_DISTANCE ? (
                  <div className="d-flex flex-column align-items-center align-content-center">
                    <h2>Please move close to the location</h2>
                    <Player
                      autoplay
                      loop
                      src="https://assets6.lottiefiles.com/private_files/lf30_qgamu43j.json"
                      style={{ height: "120", width: "248px" }}
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                    <div className="container-row mb-10">
                      <Button
                        variant="warning"
                        onClick={handleRefresh}
                        size="lg"
                      >
                        Refresh your Location
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Contact at Office</Form.Label>
                          <Form.Select
                            name="contact"
                            onChange={handleOnChange}
                            size="lg"
                          >
                            <option value="General Visit">General Visit</option>
                            {contacts.map((contact) => (
                              <option key={contact._id} value={contact.name}>
                                {contact.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Touch</Form.Label>
                          <Form.Select
                            name="touch"
                            onChange={handleOnChange}
                            size="lg"
                          >
                            <option value="">--Select a Touch--</option>
                            {TOUCHES.map((touch) => (
                              <option key={touch} value={touch}>
                                {touch}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button
                          variant="success"
                          onClick={handleCheckIn}
                          size="lg"
                        >
                          <CheckInIcon /> Check In
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CheckInCard;
