import { FaLocationArrow } from "react-icons/fa";

export const AccountAddress = ({
  account,
  distance = null,
  showDistance = false,
}) => {
  return (
    <a
      className="link"
      href={`https://maps.google.com/?q=${account.address}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="d-flex">
        <div>
          <FaLocationArrow />
        </div>
        <span className="ms-2">
          {account.address}{" "}
          {distance && showDistance ? `(${distance} miles)` : null}
        </span>
      </div>
    </a>
  );
};
