import React from "react";
import { Link } from "react-router-dom";
import { BiLinkExternal } from "react-icons/bi";
import { Button, ListGroup } from "react-bootstrap";
import { getBadgeTag, getBadgeStatus } from "../utils/helpers";
import _ from "lodash";

const AccountsList = ({ accounts, handleClickAdd }) => {
  if (!accounts) return null;

  if (accounts) {
    //Orders Accounts by statusV2 lodash,and lastVisitDaysAgoInteger
    accounts = _.orderBy(
      accounts,
      ["statusV2", "needVisit", "lastVisitDaysAgoInteger"],
      ["asc", "desc", "desc"]
    );
  }

  return (
    <ListGroup as="ol">
      {accounts &&
        accounts.map((account) => (
          <ListGroup.Item
            as="li"
            key={account._id}
            className={`d-flex flex-column ${
              account.needVisit
                ? "list-group-item-danger"
                : "list-group-item-success"
            }`}
          >
            <div className="d-flex w-100 justify-content-between">
              <h5 className="mb-1">
                {account.name}{" "}
                <Link to={`../account/${account._id}`} target="_blank">
                  <BiLinkExternal />
                </Link>
              </h5>
              <small className="text-muted">
                {account.lastVisitDaysAgo} / {account.frequency}
              </small>
            </div>
            <p className="mb-1">
              {getBadgeTag(account.type)} -{getBadgeStatus(account.statusV2)}
            </p>
            <p className="mb-1">{account.address}</p>
            <small className="text-muted">
              {account.zone} ({account.zq}){account.rep}
            </small>
            <div className="d-grid gap-2 mt-2">
              <Button
                variant="primary"
                size="lg"
                type="button"
                onClick={() => handleClickAdd(account)}
              >
                Add Route
              </Button>
            </div>
          </ListGroup.Item>
        ))}
    </ListGroup>
  );
};

export default AccountsList;
